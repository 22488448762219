<template>
  <input-field
    :label="label"
    type="text"
    @input="change"
    :value="value"
    :validation="validation"
    :dark="dark"
    placeholder='DD/MM/AAAA'
    :disabled='disabled'
    mask='##/##/####'
  />
</template>
<script>

export default {
  components: {
    InputField: () => import('@/components/InputField')
  },

  props: {
    date: Date,
    label: String,
    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      value: null
    }
  },

  methods: {
    change (value) {
      this.value = value
      this.$emit('input', value)
    }
  },

  mounted () {
    this.value = this.date
  }

}
</script>

