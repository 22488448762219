<script>
export default {
  name: 'Loading',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '1em'
    },
    direction: {
      type: String,
      default: 'vertical',
      validator: function(value) {
        return ['vertical', 'horizontal'].indexOf(value) !== -1
      }
    },
    text: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'inherit'
    }
  }
}
</script>

<template>
  <div class="loading-container" :class="{ active: active }">
    <div class="loading-frame">
      <div class="loading" :class="[direction]" :style="{ color: color, fontSize: fontSize }">
        <i class="fas fa-circle-notch fa-spin"></i>
        <label>{{ text }}</label>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  z-index: -9999;
  transition: opacity 0.3s ease-in-out 0s, z-index 0s linear 0.3s;
}
.loading-frame {
  width: 100%;
}
.loading {
  display: flex;
  align-items: center;
  padding: 1em;
  pointer-events: none;
}
.loading label {
  font-size: 0.75em;
  padding: 0.35em;
}
.loading.vertical {
  flex-direction: column;
}
.loading-container.active {
  opacity: 1;
  z-index: 100000;
  pointer-events: initial;
  transition: opacity 0.3s ease-in-out 0s, z-index 0s linear 0s;
}
</style>
