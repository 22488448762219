<script>
export default {
  name: 'ValidationSubMessage',
  components: {
    Icon: () => import('@/components/Icon')
  }
}
</script>

<template>
  <div class="validation-message">
    <icon name="error" size="small" />
    <slot />
  </div>
</template>

<style src="@/assets/styles/validation.css"></style>
