import Api from '@/services/base/Api'

class ServicoService extends Api {
  /**
   * @param {*} options
   */
  constructor(options) {
    super(options, 'servico', 'Serviço')
  }

  listar() {
    return this.get()
  }

  recuperaServicoPorCategoriaEPosto(idPosto,idCategoria ) {
    return  this.getUrl(`recupera-servico-por-categoria-posto/${idPosto}/${idCategoria}`)
  }

  recuperaServicoPorCategoriaENaoPosto(idPosto,idCategoria ) {
    return  this.getUrl(`recupera-servico-por-categoria-e-nao-posto/${idPosto}/${idCategoria}`)
  }

  buscar(id) {
    return this.get(id)
  }

  salvar(servico) {
    return this.post(servico)
  }

  deletar(servico) {
    return this.delete(servico)
  }
}

export default ServicoService.build()
