export const formatarDocumento = documento => {
  if (documento === undefined || documento === null) {
    return ''
  }

  if (documento.length === 11) {
    // CPF
    return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (documento.length === 14) {
    // CNPJ
    return documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else {
    // Outros
    return documento
  }
}

export const limparCpfCnpj = documento => {
  return documento.replace(/[.\-/]/gm, '')
}

export const rolagemParaElementoPorId = idElemento => {
  if (idElemento === undefined || idElemento == null || idElemento == '') {
    return
  }
  if (!idElemento.startsWith('#')) {
    idElemento = '#' + idElemento
  }
  document.querySelector(idElemento).scrollIntoView({ behavior: 'smooth', block: 'start' })
}
