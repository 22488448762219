import Api from '@/services/base/Api'

export class HorarioExcepcionalService extends Api {
  /**
   * @param {*} options
   */
  constructor(options) {
    super(options, 'horario-excepcional', 'horário excepcional')
  }

  listar(data, page, limit) {
    return this.post(data, `list?limit=${limit}&page=${page}`)
  }

  buscar(id) {
    return this.get(id)
  }

  salvar(horario) {
    return this.post(horario)
  }
}
export default HorarioExcepcionalService.build()
