<script>
export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    iconSvg() {
      if (!this.name) return
      return () => import(`@/assets/images/${this.name}.svg`)
    }
  },
  methods: {
    getSizeClass: function() {
      return this.size ? `is-${this.size}` : ''
    }
  }
}
</script>

<template>
  <span :class="['icon-wrapper', getSizeClass()]">
    <component :is="iconSvg" class="icon"></component>
  </span>
</template>

<style src="@/assets/styles/icon.css"></style>
