<template>
  <div class="container">
    <div class="container-title mt-5 text-center">Listagem de Serviços</div>
    <div class="mt-5 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Categoria</th>
            <th>Tempo de Atendimento</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="servico in servicos" :key="servico.id">
            <td>{{ servico.nome }}</td>
            <td>{{ servico.categoria.nome }}</td>
            <td>{{ servico.tempoAtendimento }}</td>

            <td v-if="false">
              <action @click='editar(servico.id)' tiny filled color="primary">
                <i slot="icon" class="fas fa-edit fa-xs" />
              </action>
              <action class='ml-1' @click='servicoExclusao = servico' tiny filled color="danger">
                <i slot="icon" class="fas fa-trash fa-xs" />
              </action>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="actions" v-if="false">
      <action text="Cadastrar" filled color="primary" @click="cadastrar()" />
    </div>

    <ModalConfirm
      :active="servicoExclusao != null"
      titulo="Confirmar exclusão"
      mensagem="Tem certeza que deseja remover o registro"
      @confirmar="() => { deletar(servicoExclusao.id) }"
      @fechar="servicoExclusao = null"
    >
      <p v-if="servicoExclusao">Tem certeza que deseja remover o registro <b>{{ servicoExclusao.nome }}</b>?</p>
    </ModalConfirm>
  </div>
</template>

<script>
import ServicoService from "@/services/ServicoService"
export default {
 name: 'ServicoList',
  components: {
    Action: () => import("@/components/Action"),
    ModalConfirm: () => import('@/views/ModalConfirm')
  },

  data() {
    return {
      servicoExclusao: null,
      servicos: []
    }
  },

  methods: {
    editar(id) {
       this.$router.push({ name: 'servico', params: { id } })
    },

    deletar(id) {
      ServicoService.delete(id).then(() => {
        this.$success('Serviço removido com sucesso')
        this.listar()
      })
      .catch(erro => {
        this.$error('Erro ao excluir serviço', erro)
      })
    },

    cadastrar() {
      this.$router.push({ path: 'servico' })
    },

    listar() {
      ServicoService
        .listar()
        .then(resposta => {
          this.servicos = resposta.data
        })
    }
  },

  mounted() {
    this.listar()
  }
}
</script>

<style scoped>
.actions {
  text-align: center;
  margin-top: 1rem;
}
</style>