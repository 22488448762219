<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center">Atendimento ao Público</h1>
    <div>
      <h2>{{ postoAtendimento.nome }}</h2>
      <h2>Serviços de {{categoria.nome}}</h2>
      <action
        @click="modal.active=true"
        v-show="!idAtendimento"
        flat
        text="Alterar posto e/ou categoria de atendimento"
      />
    </div>

    <div v-if="idAtendimento!==null">
      <p class="mt-5">Você está atendendo: {{ cpfAtendimento }}</p>
      <p>Horário Agendado: {{ horarioAgendado }}</p>

      <div class="table-responsive text-center">
        <action
          v-show="false"
          submit
          color="danger"
          filled
          text="Usuário não está presente"
          class="m-5"
          @click="marcarAusencia()"
        />
        <action
          submit
          color="danger"
          filled
          text="Devolver para fila"
          class="m-5"
          @click="devolverParaFila()"
        />
        <action
          submit
          color="primary"
          filled
          text="Finalizar atendimento"
          class="m-5"
          @click="finalizarAtendimento()"
        />
      </div>
    </div>
    <div v-else-if="postoAtendimento.id !== undefined">
      <div class="text-center">
        <action
          submit
          text="Atender próximo"
          filled
          color="primary"
          class="m-5"
          @click="atenderProximo()"
        />
      </div>
    </div>
    <div v-show="postoAtendimento.id !== undefined">
      <div v-if="agendamentos.length">
        <table>
          <thead>
            <tr>
              <th>CPF</th>
              <th>Horário Agendado</th>
              <th>Horário Check-in</th>
              <th>Situação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="agendamento in agendamentos" :key="agendamento.id">
              <td>{{agendamento.cpf}}</td>
              <td>{{agendamento.horarioAgendado}}</td>
              <td>{{agendamento.horarioCheckin}}</td>
              <td>
                {{agendamento.descricaoStatus}}
                <span
                  v-if="agendamento.atendimento !== undefined"
                >por {{agendamento.atendimento.cpfOperador}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>Nenhum agendamento encontrado.</div>
    </div>
    <modal :active="modal.active" @close="fecharModal">
      <h2 class="modal-title">Alterar atendimento</h2>
      <form @submit.prevent="alterarAtendimento">
        <PostoAtendimentoField v-model="idPostoAtendimento" :multiple=false />
        <select-field label="Categoria" v-model="idCategoria" :items="categorias" />
        <div class="modal-actions clearfix">
          <action text="Fechar" outlined color="lightblue" @click="fecharModal" />
          <action text="Alterar" submit filled color="primary" />
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import AtendimentoService from '@/services/AtendimentoService'

export default {
  components: {
    PostoAtendimentoField: () => import('@/components/PostoAtendimentoField'),
    SelectField: () => import('@/components/SelectField'),
    Action: () => import('@/components/Action'),
    Modal: () => import('@/components/Modal')
  },
  data() {
    return {
      postoAtendimento: {},
      cpfOperador: this.$route.params.cpfOperador,
      idSessao: this.$route.params.idSessao,
      cpfAtendimento: null,
      idAtendimento: null,
      horarioAgendado: null,
      agendamentos: [],
      idCategoria: 1,
      idPostoAtendimento: 0,
      categoria: {
        nome: 'Habilitação',
        id: 1
      },
      modal: {
        active: false
      },
      categorias: [
        { text: 'Habilitação', value: 1 },
        { text: 'Veículo', value: 2 },
        { text: 'Multas', value: 3 }
      ]
    }
  },
  methods: {
    buscarPostoAtendimento() {
      AtendimentoService.buscarPostoAtendimento(this.cpfOperador)
        .then(resposta => {
          this.postoAtendimento = resposta.data.postoAtendimento
          this.listarAgendamentos()
        })
        .catch(erro => this.$error('Erro ao carregar a tela de atendimento', erro))
    },
    listarAgendamentos() {
      this.idAtendimento = null
      AtendimentoService.listarAgendamentos(this.postoAtendimento.id).then(resposta => {
        this.agendamentos = resposta.data
        this.agendamentos.forEach(agendamento => {
          if (agendamento.atendimento !== undefined) {
            if (agendamento.atendimento.cpfOperador === this.cpfOperador) {
              this.atendimento = agendamento.atendimento
              this.cpfAtendimento = agendamento.cpf
              this.horarioAgendado = agendamento.horarioAgendado
              this.idAtendimento = agendamento.id
            }
          }
        })
      })
    },
    atenderProximo() {
      AtendimentoService.atenderProximo(this.atendimentoDTO())
        .then(resposta => {
          this.atendimento = resposta.data
          if (this.atendimento.cpf !== undefined) {
            this.$success('Atendendo CPF:', this.atendimento.cpf)
          } else {
            this.$warn('Ninguém para ser atendido. Parabéns, vá tomar um café.')
          }
          this.listarAgendamentos()
        })
        .catch(erro => {
          this.$error('Erro ao atualizar lista', erro)
        })
    },
    finalizarAtendimento() {
      AtendimentoService.finalizarAtendimento(this.atendimentoDTO())
        .then(() => {
          this.$success('Atendimento finalizado.')
          this.listarAgendamentos()
        })
        .catch(erro => {
          this.$error('Erro ao finalizar atendimento.', erro)
          this.listarAgendamentos()
        })
    },
    marcarAusencia() {
      this.listarAgendamentos()
    },
    devolverParaFila() {
      AtendimentoService.devolverParaFila(this.atendimentoDTO())
        .then(() => {
          this.$success('Devolvido para fila.')
          this.listarAgendamentos()
        })
        .catch(erro => {
          this.$error('Erro ao finalizar atendimento.', erro)
          this.listarAgendamentos()
        })
    },
    atendimentoDTO() {
      return {
        postoAtendimento: this.postoAtendimento,
        idCategoria: this.categoria.id,
        idSessao: this.idSessao,
        cpfOperador: this.cpfOperador,
        id: this.idAtendimento
      }
    }
  },
  mounted() {
    this.buscarPostoAtendimento()
  }
}
</script>

<style>
</style>

