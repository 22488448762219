import Api from '@/services/base/Api'
export class AtendimentoService extends Api {
  /**
   * @param {*} options
   */
  constructor(options) {
    super(options, 'atendimento', 'atendimento')
  }

  listarAgendamentos(atendimento) {
    return this.post(atendimento, "listar")
  }

  atenderProximo(atendimento) {
    return this.post(atendimento)
  }

  finalizarAtendimento(atendimento) {
    return this.post(atendimento, 'finalizar')
  }

  devolverParaFila(atendimento) {
    return this.post(atendimento, 'devolver')
  }

  buscarPostoAtendimento(cpfOperador) {
    return this.post(cpfOperador, 'posto-operador')
  }
}
export default AtendimentoService.build()