import axios from 'axios'
import store from './store'
const { format } = require('date-fns')

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const serializarData = function(data) {
  return format(data, "yyy-MM-dd'T'HH:mm:ss", 'Etc/UTC')
}

const tratarPayload = function(payload) {
  for (const key in payload) {
    if (payload[key] instanceof Date) {
      payload[key] = serializarData(payload[key])
    }
  }
}

http.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers = {
        Authorization: `Bearer ${store.state.token}`
      }
    }
    if (config.data) {
      tratarPayload(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
