<template>
  <span>
    <span v-if="todosDias">Todos os dias da semana</span>
    <span v-else-if="nenhumDia">Nenhum dia da semana</span>
    <span v-else>{{diasDaSemanaSelecionados}}</span>
  </span>
</template>
<script>
import { diasDaSemana } from '@/constants/dias-da-semana'
export default {
  props: {
    diasSelecionados: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    todosDias() {
      return this.diasSelecionados.every(v => v)
    },
    nenhumDia() {
      return this.diasSelecionados.every(v => !v)
    },
    diasDaSemanaSelecionados() {
      return this.diasDaSemana
      .filter((v,index) => this.diasSelecionados[index])
      .filter(v => v.mostrar)
      .map(v => v.label)
      .join(', ')
    }
  },
  data() {
    return {
      diasDaSemana
    }
  }
}
</script>
