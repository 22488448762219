import PostoAtendimentoService from '@/services/PostoAtendimentoService'
import ServicoService from '@/services/ServicoService'
import CategoriaService from '@/services/CategoriaService'
import { jwtDecode }  from 'jwt-decode'

export default {
  showOverlay({ commit }, value) {
    commit('overlay', value)
  },
  setUser({ commit }, value) {
    commit('user', value)
  },
  setToken({ commit, dispatch }, value) {
    commit('setToken', value)
    dispatch('atualizarRoles', { token: value })
  },
  atualizarRoles({ commit }, values){
    const { roles, groups } = jwtDecode(values.token)
    if (roles) {
      commit('setRoles', {
        roles: roles.split(',').map((i) => i.replace('ROLE_', ''))
      })
    }
    if (groups) {
      commit('setRoles', {
        roles: groups
      })
    }
  },
  registerComponent({ commit }, value) {
    commit('component', value)
  },
  setCidades({ commit }, value) {
    commit(
      'cidades',
      value.map(c => c.idCidadePais)
    )
  },
  setEnderecos({ commit }, value) {
    commit('enderecos', value)
  },
  setCategorias({commit, state}) {
    if(state.categorias.length) {
      return;
    }
    CategoriaService.listar().then(({ data }) => {
      commit('categorias', data)
    })
  },
  setPosto({ commit }, value) {
    commit('posto', value)
  },
  setPostosDisponiveis({ commit, state }, payload) {
    commit('carregandoPostosDisponiveis', true)
    if (state.posto) {
      PostoAtendimentoService.buscar(state.posto)
      .then(({ data }) =>
        commit('postosDisponiveis', [data])
      ).finally(() => commit('carregandoPostosDisponiveis', false))
    } else {   
       let enderecos = (state.enderecos || [])
        .filter(e => [1,3,4,5].indexOf(payload.idCategoria) === -1 || e.categoria === 1)
        .map(e => e.idCidadePais)

      if(enderecos && enderecos.length && enderecos[0] == null){
        enderecos = null
      }

      if(payload.todas){
        enderecos = null
      }
      
      PostoAtendimentoService.buscarPorCidadesECategoria(enderecos , payload.idCategoria)
      .then(({ data }) =>
        commit('postosDisponiveis', data)
      ).finally(() => commit('carregandoPostosDisponiveis', false))
    }
  },
  setCiretran({ commit }, value) {
    commit('ciretran', value)
  },
  setServicos({ commit },payload) {
    
    ServicoService.recuperaServicoPorCategoriaEPosto(payload.idPostoAtendimento, payload.idCategoria).then(({ data }) => {
        commit('servicos', data)
    })
  },
  setServicosNaoVinculadoPosto({ commit },payload) {
    ServicoService.recuperaServicoPorCategoriaENaoPosto(payload.idPostoAtendimento, payload.idCategoria).then(({ data }) => {
        commit('servicos', data)
    })
  },
  atualizarPostosAtendimento({ commit }) {
    PostoAtendimentoService.listar()
      .then(resultado => {
        commit('setPostosAtendimento', resultado.data)
        return resultado.data
      })
      .catch(erro => {
        this.$error('Erro ao listar postos de atendimento', erro)
      })
  },
  atualizarPostosAtendimentoDoOperador({ commit }) {
    PostoAtendimentoService.listarPorOperador()
      .then(resultado => {
        commit('setPostosAtendimentoOperador', resultado.data)
        return resultado.data
      })
      .catch(erro => {
        this.$error('Erro ao listar postos de atendimento', erro)
      })
  },
  setMensagemCategoriaSemPosto({ commit }, value) {
    commit('mensagemCategoriaSemPosto', value)
  },
  dispatchOutEvent({ commit }, event) {
    commit('outEvent', event)
  },
  listaTodosPostosAtendimento({ commit }) {
    PostoAtendimentoService.listar()
      .then(resultado => {
        commit('postosDisponiveis', resultado.data)
        return resultado.data
      })
      .catch(erro => {
        this.$error('Erro ao listar postos de atendimento', erro)
      })
  }
}
