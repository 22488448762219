<template>
  <div class="agendamento-microfront">
    <router-view />
    <notifications group="global" position="bottom right" :duration="4000"/>
  </div>
</template>
<script>
export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const rota = urlParams.get('rota')
    const token = urlParams.get('token')
    if (token) {
      this.$store.dispatch('setToken', token)
      // TODO: Remover esse cara depois de corrigir o bug do PostoAtendimentoField
      this.$store.dispatch('atualizarPostosAtendimento').then(() => {})
    }
    if (rota) {
      this.$router.replace({ name: rota })
    }
  }
}
</script>
<style src="@/assets/styles/global.css"></style>
<style>
@import 'https://fonts.googleapis.com/css?family=Lato:100,300,400,700';
@import 'https://use.fontawesome.com/releases/v5.0.7/css/all.css';
</style>
<style>
.vue-notification {
  font-size: 1.2rem !important;
  padding: 1rem 1.2rem !important;
}
</style>
