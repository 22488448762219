<template>
  <div class="container">
    <div class="container-title mt-5 text-center">Listagem de Postos de Atendimento</div>
    <div class="mt-5 table-responsive">
      <div>
        <input-field
            label="Pesquisar"
            v-model.trim="pesquisa"
          />
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Endereço</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="postoAtendimento in postosFiltrados" :key="postoAtendimento.id">
            <td>{{ postoAtendimento.nome }}</td>
            <td>{{ postoAtendimento.endereco }}</td>

            <td v-if="false">
              <action @click='editar(postoAtendimento.id)' tiny filled color="primary">
                <i slot="icon" class="fas fa-edit fa-xs" />
              </action>
              
              <action v-if="false" class='ml-1' @click='postoAtendimentoExclusao = postoAtendimento' tiny filled color="danger">
                <i slot="icon" class="fas fa-trash fa-xs" />
              </action>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalConfirm
      :active="postoAtendimentoExclusao != null"
      titulo="Confirmar exclusão"
      mensagem="Tem certeza que deseja remover o registro"
      @confirmar="() => { deletar(postoAtendimentoExclusao.id) }"
      @fechar="postoAtendimentoExclusao = null"
    >
      <p v-if="postoAtendimentoExclusao">Tem certeza que deseja remover o registro <b>{{ postoAtendimentoExclusao.nome }}</b>?</p>
    </ModalConfirm>
  </div>
</template>

<script>
import PostoAtendimentoService from "@/services/PostoAtendimentoService"

export default {
  components: {
    Action: () => import("@/components/Action"),
    ModalConfirm: () => import('@/views/ModalConfirm'),
    InputField: () => import("@/components/InputField"),
  },

  data() {
    return {
      postoAtendimentoExclusao: null,
      postosAtendimento: [],
      pesquisa: ''
    }
  },

  computed: {
    postosFiltrados() {
      var dis = this
      if (this.pesquisa && this.pesquisa.length >= 3) {
        return this.postosAtendimento.filter(item => {
          return item.nome.toLowerCase().indexOf(dis.pesquisa.toLowerCase()) > -1//item.type.toLowerCase().indexOf('cri') > -1
        })
      } else {
        return this.postosAtendimento
      }
    }
  },

  methods: {
    editar(id) {
       this.$router.push({ name: 'posto-atendimento', params: { id } })
    },
    deletar(id) {
      PostoAtendimentoService.delete(id).then(() => {
        this.$success('Posto removido com sucesso')
        this.listar()
      })
      .catch(erro => {
        this.$error('Erro ao excluir posto', erro)
      })
    },

    //A principio sem cadastrar, pois não temos vnculo de posto com serviços.0
    /*
    cadastrar() {
      this.$router.push({ path: 'posto-atendimento' })
    },
    */

    listar() {
      PostoAtendimentoService
        .listar()
        .then(resposta => {
          this.postosAtendimento = resposta.data
        })
    }
  },

  mounted() {
    this.listar()
  }
}
</script>

<style scoped>
.actions {
  text-align: center;
  margin-top: 1rem;
}
</style>