<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'InputField',
  components: {
    Action: () => import('@/components/Action'),
    Icon: () => import('@/components/Icon'),
    ValidationMessage: () => import('@/components/ValidationMessage')
  },
  directives: {
    mask
  },
  data () {
    return {
      isFocused: false,
      passwordVisible: false,
      internalType: this.type,
      autofill: false
    }
  },
  props: {
    autocomplete: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    floatingLabel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: null,
    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },
    type: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabledVisibility: {
      type: Boolean,
      default: false
    },
    mask: {
      // eslint-disable-next-line
      type: String | Array,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasValue () {
      return (this.value != null && this.value.toString().length > 0) || this.autofill || this.placeholder !== null
    },
    isPassword () {
      return this.type === 'password'
    },
    isRequired () {
      return this.validation.$params != null && typeof this.validation.$params.required === 'object'
    },
    visibilityIcon () {
      return this.passwordVisible ? 'eye' : 'eye-off'
    },
    autocapitalize () {
      return this.isPassword ? 'none' : null
    },
    mascaraConfigurada () {
      if (!this.mask) {
        return null
      }

      return {
        mask: this.mask,
        tokens: {
          // Tokens padrão do VueTheMask
          '#': { pattern: /\d/ },
          'X': { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
          'S': { pattern: /[a-zA-Z]/ },
          'A': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
          'a': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
          '!': { escape: true },
          // Tokens personalizados
          'F': {
            pattern: /[0-9a-fA-F]/,
            transform: v => v.toLocaleUpperCase()
          }
        }
      }
    }
  },
  methods: {
    updateValue (value) {
      this.autofill = false
      typeof this.validation.$touch === 'function' && this.validation.$touch()
      this.$emit('input', value)
    },
    updateFocus () {
      this.isFocused = true
      typeof this.validation.$reset === 'function' && this.validation.$reset()
    },
    updateBlur () {
      this.isFocused = false
      typeof this.validation.$touch === 'function' && this.validation.$touch()
    },
    changeVisiblity () {
      this.passwordVisible = !this.passwordVisible
      this.internalType = this.passwordVisible ? 'text' : 'password'
      this.$refs.input.focus()
    },
    checkAutoFill () {
      const onAnimationStart = ({ animationName }) => {
        if (animationName === 'autofilldark' || animationName === 'autofill') {
          this.autofill = true
        }
      }
      this.$refs.input.addEventListener('animationstart', onAnimationStart, false)
    }
  },
  mounted () {
    this.checkAutoFill()
  }
}
</script>

<template>
  <div class="form-item" :class="{
      'has-error': validation.$error,
      'has-value': hasValue,
      'has-focus': isFocused,
      'is-disabled': disabled,
      'theme-dark': dark
    }">
    <label class="form-label" :for="'input' + _uid" v-if="label">{{ label }} <span v-if="isRequired && !disabled">*</span></label>
    <div class="form-input-wrapper">
      <input class="form-input" :id="'input' + _uid"
        spellcheck="false"
        ref="input"
        :type="internalType"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        v-mask="mascaraConfigurada"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur()"
        v-if="mask"
      >
      <input class="form-input" :id="'input' + _uid"
        spellcheck="false"
        ref="input"
        :autofocus="autofocus"
        :type="internalType"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur()"
        v-if="!mask"
      >
      <div class="form-input-append" ref="append">
        <action icon @click="changeVisiblity()" v-if="isPassword">
          <icon slot="icon" :name="visibilityIcon"/>
        </action>
      </div>
    </div>
    <div class="form-input-details">
      <validation-message :validation="validation"></validation-message>
    </div>
  </div>
</template>

<style src="@/assets/styles/form.css"></style>
