<template>
    <div :class="{'alert-message text-center' : true, [`alert-${type}`] : type}">
        <h2 v-if='title'>{{title}}</h2>
        <p v-html='content' v-if='content'></p>
        <p><slot/></p>
    </div>
</template>
<script>
export default {

  props: {
    type: String,
    title: String,
    content: String
  }

}
</script>
<style scoped>
    .alert-message {
        padding: 10px;
        background: #efd612;
    }

    .alert-danger {
        background: #E0231F;
        color: white;
    }

    .alert-info {
        background: #7FC0E4;
    }
</style>

