export default {
  overlay: false,
  user: {
  /** 
    nome:"Darth Vader",
    cpf:'75299034032',
    email:'vaderzinho@sith.com'*/
  
  },
  roles: [],
  token: null,
  postosAtendimento: [],
  postosAtendimentoOperador: [],
  carregandoPostosDisponiveis: false,
  postosDisponiveis: [],
  servicos: [],
  ciretran: {
    /*nome: "FLORIANÓPOLIS"*/
  },
  cidades: [],
  enderecos: [/**[{idCidadePais: 6190, categoria: 2},{idCidadePais: 6190, categoria: 1}]*/],
  categorias: [],
  mensagemCategoriaSemPosto: null
}
