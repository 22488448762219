<script>
export default {
  name: 'checkboxItem',
  components: {
    Icon: () => import('@/components/Icon')
  },
  data () {
    return {
      isFocused: false,
      isChecked: this.checked
    }
  },
  props: {
    alignRight: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    labelWarning: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: null,
    index: {
      type: Number,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    unique: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    checked (value) {
      this.isChecked = value
    }
  },
  methods: {
    updateValue (item) {
      this.isChecked = item.checked
      this.$emit('change', { 'value': item.value, 'checked': item.checked, 'unique': this.unique })
      this.$emit('input', item.checked)
    },
    updateFocus () {
      this.isFocused = true
    },
    updateBlur () {
      this.isFocused = false
    },
    checkLinkInside (event) {
      event.target.nodeName === 'A' && this.$emit('label-link')
    }
  }
}
</script>

<template>
  <div class="form-checkbox-item" :class="{ 'is-disabled': disabled, 'is-readonly': readonly, 'is-checked': isChecked, 'has-focus': isFocused }">
    <input class="form-checkbox" :id="'checkbox-' + _uid + '-' + index"
      type="checkbox"
      :name="'checkbox-' + _uid"
      :disabled="disabled || readonly"
      :checked="isChecked"
      :value="value"
      @focus="updateFocus()"
      @blur="updateBlur()"
      @change="updateValue($event.target)">
    <div class="form-checkbox-label">
      <label :for="'checkbox-' + _uid + '-' + index" :class="{'align-right': alignRight }" @click="checkLinkInside($event)">
        <icon name="checkbox" v-if="isChecked" />
        <icon name="checkbox-outline" v-if="!isChecked" />
        <span v-html="label" />
      </label>
      <slot/>
    </div>
  </div>
</template>

<style src="@/assets/styles/form.css"></style>
<style src="@/assets/styles/checkbox.css"></style>
