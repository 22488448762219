import { render, staticRenderFns } from "./DetalhePostoAtendimento.vue?vue&type=template&id=66ee68ac&scoped=true"
import script from "./DetalhePostoAtendimento.vue?vue&type=script&lang=js"
export * from "./DetalhePostoAtendimento.vue?vue&type=script&lang=js"
import style0 from "./DetalhePostoAtendimento.vue?vue&type=style&index=0&id=66ee68ac&prod&scoped=true&lang=css"
import style1 from "./DetalhePostoAtendimento.vue?vue&type=style&index=1&id=66ee68ac&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ee68ac",
  null
  
)

export default component.exports