<script>
export default {
  name: 'modal',
  components: {
    Action: () => import('@/components/Action'),
    Icon: () => import('@/components/Icon')
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    active () {
      if (this.active) {
        document.body.classList.add('modal-open')
        window.addEventListener('keydown', this.checkEscape)
        this.$emit('open')
      } else {
        document.body.classList.remove('modal-open')
        window.removeEventListener('keydown', this.checkEscape)
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    checkEscape (event) {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },
    checkClass () {
      if (this.active) {
        document.body.classList.add('modal-open')
        window.addEventListener('keydown', this.checkEscape)
        this.$emit('open')
      } else {
        document.body.classList.remove('modal-open')
        window.removeEventListener('keydown', this.checkEscape)
      }
    }
  },
  mounted () {
    document.body.appendChild(this.$el)
  },
  destroyed () {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.checkClass()
  }
}
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-blocker" v-if="active" tabindex="-1">
      <div class="modal">
        <action icon class="btn-close" @click="closeModal" v-if="cancel">
          <icon slot="icon" name="close"/>
        </action>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style src="@/assets/styles/modal.css"></style>
