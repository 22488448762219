import { isFuture, endOfDay, differenceInDays, isAfter, isWeekend } from 'date-fns'

export function limiteDeTrintaDias (value) {
  if(value === null) {
    return true;
  }
  return differenceInDays(value,new Date()) <= 30
}

export function dataFutura (value) {
  if(value === null) {
    return true
  }
  return isFuture(endOfDay(value))
}

export const depoisDe = param => (value, vm) => {

  if(!vm[param]) {
    return false
  }
  return isAfter(value, vm[param])
}

export const diaUtil = (value) => {
  return !isWeekend(value)
}

export function nomeCompleto (value) {
  if (!value) {
    return false
  }
  if (!value.split) {
    return false
  }
  return value.trim().split(' ').length > 1
}

export function codigoValidacao (value) {
  if (value === null) {
    return false
  }
  return value.length === 14
}

function digitoVerificador (value, size, invalid) {
  if (value == null) {
    return false
  }
  const valorSemMascara = value.replace(/[^\d]+/g, '')
  if (!size || valorSemMascara.length !== size) {
    return false
  }
  if (invalid && invalid.indexOf(valorSemMascara) !== -1) {
    return false
  }
  const tamanho = valorSemMascara.length - 2
  const numeros = valorSemMascara.substring(0, tamanho + 1)
  const digitos = valorSemMascara.substring(tamanho)
  let soma = 0
  let peso = numeros.length <= 10 ? numeros.length : (numeros.length - 8)
  let i = 0
  for (i = 0; i < tamanho; i++) {
    soma += numeros.charAt(i) * peso--
    if (peso < 2) {
      peso = 9
    }
  }
  let resto = soma % 11
  let digito = (resto < 2) ? 0 : (11 - resto)
  if (('' + digito) !== digitos.charAt(0)) {
    return false
  }
  soma = 0
  peso = numeros.length <= 10 ? numeros.length + 1 : (numeros.length - 7)
  i = 0
  for (i = 0; i < tamanho + 1; i++) {
    soma += numeros.charAt(i) * peso--
    if (peso < 2) {
      peso = 9
    }
  }
  resto = soma % 11
  digito = (resto < 2) ? 0 : (11 - resto)
  return (('' + digito) === digitos.charAt(1))
}

export function mensagemErroCpf (value) {
  if (value === null || value === undefined || value.length !== 14) {
    return 'CPF precisa ter 11 dígitos'
  }
  const cpfValido = digitoVerificador(value, 11, [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ])
  return cpfValido ? null : 'CPF inválido'
}

export function mensagemErroCnpj (value) {
  if (value === null || value === undefined || value.length !== 18) {
    return 'CNPJ precisa ter 14 dígitos'
  }
  return digitoVerificador(value, 14, ['00000000000000']) ? null : 'CNPJ inválido'
}

export function mensagemErroCpfCnpj (value) {
  if (value === null || (value.length !== 14 && value.length !== 18)) {
    return 'CPF/CNPJ precisa ter 11 ou 14 dígitos'
  }
  if (value.length === 14) { // CPF
    return mensagemErroCpf(value)
  } else { // CNPJ
    return mensagemErroCnpj(value)
  }
}

export function cpf (value) {
  return mensagemErroCpf(value) == null
}

export function cnpj (value) {
  return mensagemErroCnpj(value) == null
}

export function cpfCnpj (value) {
  return mensagemErroCpfCnpj(value) == null
}

export function horarioValido(value) {
  if (!value || typeof value != 'string') {
    return false;
  }

  const trimmed = value.trim()

  if (!trimmed.match(/^\d\d:\d\d:\d\d$/)) {
    return false
  }

  const partes = trimmed.split(':').map(v => +v)
  return partes[0] <= 23 && partes[1] <= 59 && partes[2] <= 59
}