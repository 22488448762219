<template>
  <div class="container">
    <div>
      <h1 class="container-title mt-5 text-center">Acompanhar Agendamentos</h1>
    </div>

    <loading
      font-size="1.7rem"
      text="Carregando"
      direction="vertical"
      color="#2D52A0"
      :active="isLoading"
    />

    <div v-if="agendamentos.length && !isLoading" class="mt-3 card-list-container">
      <div
        class="card"
        v-for="agendamento in agendamentos"
        :key="agendamento.id"
        :class="{ 'is-agendado': agendamento.statusId == 2 }"
      >
        <div class="card-row info-header">
          <div class="info-title">Horário</div>
          <div class="info-data">{{ agendamento.horarioAgendado | datetime }}h</div>
        </div>
        <div class="card-content">
          <div class="info-section">
            <div class="card-row">
              <div class="info-title">Posto</div>
              <div class="info-data">{{ agendamento.postoAtendimento }}</div>
            </div>
            <div class="card-row" v-if="agendamento.endereco">
              <div class="info-title">Endereço</div>
              <div class="info-data uppercase">{{ agendamento.endereco }}</div>
            </div>
            <div class="card-row">
              <div class="info-title">Serviço</div>
              <div class="info-data">{{ agendamento.servico }}</div>
            </div>
            <div class="card-row info-status">
              <div class="info-title">Status</div>
              <div class="info-data">
                <span>{{ agendamento.statusDescricao }}</span>
              </div>
              <div class="actions" v-if="agendamento.statusId == 2">
                <action
                  class="ml-2"
                  @click="loadAgendamentoDetalhe(agendamento)"
                  tiny
                  filled
                  color="primary"
                >
                  <i slot="icon" class="fas fa-print fa-xs" />
                </action>
                <action
                  class="ml-2"
                  @click="cancelarAgendamentoModal(agendamento)"
                  tiny
                  filled
                  color="danger"
                  :class="{ 'btn-disabled': agendamento.expirado }"
                >
                  <i slot="icon" class="fas fa-trash fa-xs" />
                </action>
              </div>
            </div>

            <div
              class="card-actions d-flex justify-content-center"
              v-if="agendamento.statusId == 2"
            >
              <action
                class="ml-2"
                @click="loadAgendamentoDetalhe(agendamento)"
                tiny
                filled
                color="primary"
              >
                <i slot="icon" class="fas fa-print fa-xs" />
              </action>
              <action
                class="ml-2"
                @click="cancelarAgendamentoModal(agendamento)"
                tiny
                filled
                color="danger"
                :class="{ 'btn-disabled': agendamento.expirado }"
              >
                <i slot="icon" class="fas fa-trash fa-xs" />
              </action>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5" v-if="!agendamentos.length && !isLoading">
      <p>Nenhum agendamento encontrado</p>
    </div>
    <div class="actions-container mt-3" v-if="!isLoading">
      <action
        submit
        color="lightblue"
        outlined
        small
        text="Agendar Horário"
        class="btn-agendamento mt-lg-4 mt-3 btn-sm"
        :url="{ name: 'agendamento' }"
      />
    </div>

    <ModalConfirm
      :active="agendamentoCancelamento != null"
      titulo="Cancelamento"
      confirmarLabel="Confirmar"
      cancelarLabel="Não Cancelar"
      @confirmar="() => cancelarAgendamento(agendamentoCancelamento.id)"
      @fechar="agendamentoCancelamento = null"
    >
      <div v-if="agendamentoCancelamento" class="cancelamento-container">
        <span>Tem certeza que deseja cancelar o agendamento abaixo?</span>
        <div class="dados-cancelamento">
          <table>
            <tbody>
              <tr>
                <th>Horário:</th>
                <td>{{ agendamentoCancelamento.horarioAgendado | datetime }}</td>
              </tr>
              <tr>
                <th>Serviço:</th>
                <td>{{ agendamentoCancelamento.servico }}</td>
              </tr>
              <tr>
                <th>Posto:</th>
                <td>{{ agendamentoCancelamento.postoAtendimento }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ModalConfirm>
    <ModalHorarioAgendado
      :active="agendamentoDetalhe != null"
      titulo="Atendimento Agendado"
      :agendamento="agendamentoDetalhe"
      @fechar="agendamentoDetalhe = null"
    />
  </div>
</template>
<script>
import AgendamentoService from '@/services/AgendamentoService'
export default {
  components: {
    Loading: () => import('@/components/Loading'),
    Action: () => import('@/components/Action'),
    ModalConfirm: () => import('@/views/ModalConfirm'),
    ModalHorarioAgendado: () => import('@/components/ModalHorarioAgendado')
  },

  data() {
    return {
      agendamentoDetalhe: null,
      agendamentoCancelamento: null,
      agendamentos: [],
      isLoading: false
    }
  },
  validations: {},
  computed: {
    userCpf() {
      return this.$store.state.user.cpf || null
    }
  },
  methods: {
    listar() {
      this.isLoading = true
      AgendamentoService.listarPorCpf()
        .then(resposta => {
          this.agendamentos = resposta.data
        })
        .catch(erro => {
          this.$error('Erro ao cancelar o agendamento', erro)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    cancelarAgendamento(id) {
      this.isLoading = true
      AgendamentoService.cancelarAgendamento(id)
        .then(() => {
          this.$success('Agendamento cancelado com sucesso')
          this.listar()
        })
        .catch(erro => {
          this.$error('Erro ao cancelar o agendamento', erro)
          this.isLoading = false
        })
    },
    loadAgendamentoDetalhe(agendamento) {
      this.agendamentoDetalhe = {
        nome: agendamento.nome,
        cpf: this.userCpf,
        categoria: agendamento.categoria,
        posto: agendamento.postoAtendimento,
        servico: agendamento.servico,
        dataHorario: agendamento.horarioAgendado,
        id: agendamento.id,
        endereco: agendamento.endereco
      }
    },
    cancelarAgendamentoModal(agendamento) {
      if (agendamento.expirado) {
        this.avisoExpirado()
        return
      }
      this.agendamentoCancelamento = agendamento;
    },
    avisoExpirado() {
      this.$warn(
        'Aviso',
        'O cancelamento de agendamentos pode ser efetuado até 24 horas antes do horário agendado, após isso o mesmo não pode ser mais cancelado. Caso não tenha comparecido ao agendamento e necessite de atendimento antes de 90 dias, dirija-se presencialmente a uma unidade do Detran.',
        { duration: -1 }
      )
    }
  },
  mounted() {
    this.listar()
  }
}
</script>

<style src="@/assets/styles/card.css"></style>

<style scoped>
.actions-container {
  display: flex;
  justify-content: center;
}
.cancelamento-container {
  font-size: 1rem;
}

.cancelamento-container table {
  font-size: 1rem;
  text-align: left;
  margin-top: 1rem;
}

.cancelamento-container th {
  font-weight: normal;
}

.cancelamento-container td {
  font-weight: bold;
  border: none;
}

.card-actions {
  display: none !important;
}

.btn-disabled {
  cursor: not-allowed;
  opacity: .5;
}

@media (max-width: 360px) {
  .info-status .actions {
    display: none;
  }

  .card-actions {
    display: flex !important;
  }
}

@media print {
  .container {
    display: none;
  }
}
</style>
