<script>
export default {
  name: 'SelectField',
  components: {
    Icon: () => import('@/components/Icon'),
    ValidationMessage: () => import('@/components/ValidationMessage')
  },
  data() {
    return {
      isMounted: false,
      dropdownOpen: false,
      focus: false,
      mutableValue: null,
      keyIndex: -1,
      filter: null,
      searching: null
    }
  },
  props: {
    value: {
      default: null
    },
    validation: {
      type: Object,
      default: function() {
        return {}
      }
    },
    label: {
      type: String
    },
    hint: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectAllOption: {
      type: Boolean,
      default: true
    },
    maxItensVisible: {
      type: Number,
      default: 3
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    emitOnlyIfChanged: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    items() {
      this.mutableValue = this.multiple ? [] : this.mutableValue
    },
    multiple() {
      this.mutableValue = []
    },
    value(value) {
      this.mutableValue = (this.multiple && !value) ? [] : value
    }
  },
  computed: {
    hasValue() {
      return (
        (this.multiple && this.mutableValue.length > 0) ||
        (!this.multiple && this.mutableValue !== null)
      )
    },
    hasValidation() {
      return Object.keys(this.validation).length
    },
    isUniqueInsideForm() {
      return this.isMounted && this.$refs.input.form == null
    },
    isAllOptionSelected() {
      return this.multiple && this.mutableValue.length === this.items.length
    },
    isRequired() {
      return (
        this.validation.$params != null &&
        typeof this.validation.$params.required === 'object'
      )
    },
    getValues() {
      if (this.multiple && this.mutableValue.length >= this.maxItensVisible) {
        return `Selecionado(s) ${this.mutableValue.length} item(s)`
      } else if (this.mutableValue !== null) {
        var tempMutableValue = this.multiple
          ? this.mutableValue
          : [this.mutableValue]

        return this.items
          .filter(item => {
            return tempMutableValue.indexOf(item.value) >= 0
          })
          .map(function(item) {
            return item.text
          })
          .toString()
          .replace(/,/g, ', ')
      }
      return null
    }
  },
  methods: {
    toggleDropdown() {
      if (!this.disabled) {
        this.dropdownOpen = !this.dropdownOpen
        if (!this.hasValue) {
          this.keyIndex = -1
        }
        if(this.searchable) {
          this.searching = true
        }
      }
    },
    clickLabel() {
      this.$refs.selectWrapper.click()
    },
    closeDropdown() {
      this.dropdownOpen = false
      this.searching = false
    },
    selectOption(item, index) {
      if (item.fixedValue !== undefined || item.disabled) {
        return
      }
      this.keyIndex = index
      const initialValue = this.mutableValue
      if (this.multiple) {
        var indexArray = this.mutableValue.indexOf(item.value)
        indexArray >= 0
          ? this.mutableValue.splice(indexArray, 1)
          : this.mutableValue.push(item.value)
      } else {
        this.mutableValue = item.value
        this.closeDropdown()
      }
      if (!this.emitOnlyIfChanged || (initialValue != this.mutableValue)) {
        this.$emit('input', this.mutableValue)
      }
    },
    selectAll() {
      const initialValue = this.mutableValue
      if (this.isAllOptionSelected) {
        // Desselecionar todos; Manter selecionados os que tem valor fixo "true"
        this.mutableValue = this.items
          .filter(item => item.fixedValue === true)
          .map(item => item.value)
      } else {
        // Selecionar todos; Não selecionar os que tem valor fixo "false"
        this.mutableValue = this.items
          .filter(
            item => item.fixedValue === undefined || item.fixedValue === true
          )
          .map(item => item.value)
      }
      if (!this.emitOnlyIfChanged || (initialValue != this.mutableValue)) {
        this.$emit('input', this.mutableValue)
      }
    },
    isOptionSelected(item) {
      return (
        (this.multiple && this.mutableValue.indexOf(item.value) >= 0) ||
        this.mutableValue === item.value
      )
    },
    keyUp() {
      if (this.dropdownOpen && this.keyIndex > 0) {
        this.keyIndex--
      }
    },
    keyDown() {
      if (this.dropdownOpen && this.keyIndex < this.items.length) {
        this.keyIndex++
      } else {
        this.dropdownOpen = true
        this.keyIndex = this.multiple ? 0 : 1
      }
    },
    keyEnter() {
      if (!this.dropdownOpen) {
        this.dropdownOpen = true
        if (this.keyIndex < 0) {
          this.keyIndex = 0
        }
      } else {
        if (this.keyIndex > 0) {
          this.selectOption(this.items[this.keyIndex - 1], this.keyIndex)
        } else if (this.keyIndex === 0) {
          this.selectAll()
        } else {
          this.closeDropdown()
        }
      }
    },
    highlight(index) {
      this.keyIndex = index
    },
    removeHighlight() {
      this.keyIndex = -1
    },
    addFocus() {
      if (!this.disabled) {
        this.focus = true
        typeof this.validation.$reset === 'function' && this.validation.$reset()
      }
    },
    removeFocus() {
      if (this.focus) {
        this.focus = false
        typeof this.validation.$touch === 'function' && this.validation.$touch()
      }
    },
    outside() {
      this.closeDropdown()
      this.removeFocus()
    }
  },
  created() {
    this.mutableValue =
      this.value !== null ? this.value : this.multiple ? [] : null
  },
  mounted() {
    this.isMounted = true
  }
}
</script>

<template>
  <div
    class="form-item"
    :class="{
      'has-value': hasValue,
      'has-focus': focus,
      'has-error': validation.$error,
      'is-disabled': disabled,
      'theme-dark': dark
    }"
    v-click-outside="outside"
  >
    <label class="form-label" v-if="label" @click="clickLabel()"
      >{{ label }}
      <span v-if="!isRequired && !disabled && !isUniqueInsideForm"
        >*</span
      ></label
    >
    <div
      :tabindex="disabled ? -1 : 0"
      class="form-input-wrapper form-select-wrapper"
      ref="selectWrapper"
      :class="{ 'is-multiselect': multiple, 'is-opened': dropdownOpen }"
      @click="toggleDropdown(), addFocus()"
      @focus="addFocus()"
      @blur="closeDropdown(), removeFocus()"
      @keyup.27="closeDropdown()"
      @keydown.up.prevent="keyUp()"
      @keydown.down.prevent="keyDown()"
      @keydown.enter.prevent="keyEnter()"
    >
      <input
        v-if='!searching'
        class="form-input"
        readonly="readonly"
        ref="input"
        tabindex="-1"
        :value="getValues"
      />
      <input
        v-else
        class="form-input"
        ref="input"
        tabindex="-1"
        v-model='filter'
      />
      <ul class="form-select-dropdown" v-if="dropdownOpen">
        <li
          class="form-select-dropdown-item"
          v-if="multiple && selectAllOption"
          :class="{ 'is-highlight': keyIndex == 0 }"
          @click.stop="selectAll()"
          @mouseover="highlight(0)"
          @mouseleave="removeHighlight()"
        >
          <icon name="checkbox-outline" v-if="!isAllOptionSelected"></icon>
          <icon name="checkbox" v-if="isAllOptionSelected"></icon>
          Selecionar todos
        </li>
        <li
          class="form-select-dropdown-item"
          v-for="(item, index) in items"
          :key="index"
          :class="{ 'is-highlight': keyIndex == index + 1, 'is-hidden': (((item.text) || '') + '').toLowerCase().indexOf((filter || '').toLowerCase()) === -1 && filter, 'is-disabled': item.disabled  }"
          @click.stop="selectOption(item, index + 1)"
          @mouseover="!item.disabled && highlight(index + 1)"
          @mouseleave="removeHighlight()"
        >
          <icon
            name="checkbox-outline"
            v-if="multiple && !isOptionSelected(item)"
          ></icon>
          <icon
            name="checkbox"
            v-if="multiple && isOptionSelected(item)"
          ></icon>
          {{ item.text }}
        </li>
      </ul>
      <div class="form-input-append">
        <icon name="arrow-down"></icon>
      </div>
    </div>
    <div class="form-input-details">
      <slot name="details"></slot>
      <span
        class="form-input-hint"
        v-if="hint && !validation.$error && !$slots.details"
        >{{ hint }}</span
      >
      <validation-message :validation="validation"></validation-message>
    </div>
  </div>
</template>

<style src="@/assets/styles/form.css"></style>
<style src="@/assets/styles/select.css"></style>
<style scoped>
.form-select-dropdown-item.is-disabled {
  cursor: not-allowed;
  opacity: .3;
}
</style>
