<template>
  <div v-if="agendamentos">
    <h1 class="container-title mt-5 text-center">Agendamentos</h1>

    <div v-if="agendamentos.length" class="mt-3 card-list-container">
      <div
        class="card"
        v-for="agendamento in agendamentos"
        :key="agendamento.id"
        :class="{ 'is-agendado': agendamento.statusId == 2 }"
      >
        <div class="card-row info-header">
          <div class="info-title">Horário</div>
          <div class="info-data">{{ agendamento.horarioAgendado | datetime }}h</div>
        </div>
        <div class="card-content">
          <div class="info-section">
            <div class="card-row info-important">
              <div class="info-title">Nome</div>
              <div class="info-data">{{ agendamento.nome }}</div>
            </div>
            <div class="card-row">
              <div class="info-title">CPF</div>
              <div class="info-data">{{ agendamento.cpf | cpf }}</div>
            </div>
            <div class="card-row">
              <div class="info-title">Posto</div>
              <div class="info-data">{{ agendamento.postoAtendimento }}</div>
            </div>
            <div class="card-row">
              <div class="info-title">Setor</div>
              <div class="info-data">{{ agendamento.categoria }}</div>
            </div>
            <div class="card-row">
              <div class="info-title">Serviço</div>
              <div class="info-data">{{ agendamento.servico }}</div>
            </div>
            <div class="card-row">
              <div class="info-title">Status</div>
              <div class="info-data">{{ agendamento.statusDescricao }}</div>
            </div>
          </div>
          <div class="card-actions d-flex flex-column justify-content-between">
            <action
              @click="efetuarCheckin(agendamento)"
              filled
              small
              color="primary"
              text="Check-in"
              v-if="agendamento.statusId === 2 && !gerenciar"
            >
            </action>
            <action
              @click="cancelar(agendamento)"
              filled
              small
              color="danger"
              text="Cancelar"
              :class="{ 'btn-disabled': agendamento.expirado }"
              v-if="agendamento.statusId === 2 && gerenciar"
            >
            </action>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-4 aviso-sem-agendamento">
      <slot name="empty" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Action: () => import('@/components/Action')
  },
  props: {
    agendamentos: Array,
    gerenciar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    efetuarCheckin(agendamento) {
      this.$emit('checkin', agendamento)
    },
    cancelar(agendamento) {
      if (agendamento.expirado) {
        this.avisoExpirado()
        return
      }
      this.$emit('cancelar', agendamento)
    },
    avisoExpirado() {
      this.$warn(
        'Aviso',
        'O cancelamento de agendamentos pode ser efetuado até 24 horas antes do horário agendado, após isso o mesmo não pode ser mais cancelado. Caso não tenha comparecido ao agendamento e necessite de atendimento antes de 90 dias, dirija-se presencialmente a uma unidade do Detran.',
        { duration: -1 }
      )
    }
  }
}
</script>
<style scoped>
.aviso-sem-agendamento {
  text-align: center;
  font-size: 1.6em;
  font-family: 'Lato';
}
.btn-disabled {
  cursor: not-allowed;
  opacity: .5;
}
</style>
