<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center">Hor&aacute;rios excepcionais</h1>
    <PostoAtendimentoField v-model="filter.idPosto" @input='atualizarLista(null)' :multiple=false opcaoNenhum/>
    <input-field
            label="Descrição"
            type='text'
            v-model.trim="filter.descricao"
            @input="atualizarLista(null)"
          />
    <div class="mt-5 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Posto atendimento</th>
            <th>Data início</th>
            <th>Data fim</th>
            <th>
              Repetir
              <br />anualmente
            </th>
            <th>Categoria</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="horario in horarios" :key="horario.id" :data-id="horario.id">
            <td>{{ horario.descricao }}</td>
            <td>
              <span
                v-if="horario.postoAtendimento && horario.postoAtendimento.id"
              >{{ horario.postoAtendimento.nome }}</span>
              <span v-else>
                <i>(Todos)</i>
              </span>
            </td>
            <td>{{ horario.dataInicio | datetime }}</td>
            <td>{{ horario.dataFim | datetime }}</td>
            <td>
              <i v-if="horario.fixo" class="fas fa-check" />
            </td>
            <td>{{categorias[horario.idCategoria] || '(Todas)'}}</td>
            <td>
              <div class='row'>
              <action @click="editar(horario.id)" tiny filled color="primary">
                <i slot="icon" class="fas fa-edit fa-xs" />
              </action>
              <action @click="horarioExclusao = horario" tiny filled color="danger" class='ml-1'>
                <i slot="icon" class="fas fa-trash fa-xs" />
              </action>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination class='mt-5' :count="count" @changePage='atualizarLista($event)' :page='page' :limit='limit'/>
    </div>

    <div class="actions">
      <action text="Novo Registro" filled color="primary" @click="editar('new')" />
    </div>

    <!-- TODO: Implementar paginação -->

    <ModalConfirm
      :active="horarioExclusao != null"
      titulo="Confirmar exclusão"
      @confirmar="deletar(horarioExclusao.id)"
      @fechar="horarioExclusao = null"
    >
      <p v-if="horarioExclusao">
        Tem certeza que deseja remover o registro
        <b>{{ horarioExclusao.descricao }}</b>?
      </p>
    </ModalConfirm>
  </div>
</template>

<script>
import HorarioExcepcionalService from '@/services/HorarioExcepcionalService'
export default {
  components: {
    Action: () => import('@/components/Action'),
    ModalConfirm: () => import('@/views/ModalConfirm'),
    Pagination: () => import('@/components/Pagination'),
    InputField: () => import('@/components/InputField'),
    PostoAtendimentoField: () => import('@/components/PostoAtendimentoField'),
  },

  data() {
    return {
      horarioExclusao: null,
      horarios: [],
      page: 1,
      limit: 10,
      filter: {
        idPosto: null
      }
    }
  },

  computed: {
    categorias() {
      return this.$store.state.categorias.reduce((a,b) => Object.assign(a, {[b.id] : b.nome}),{})
    }
  },

  methods: {

    editar(id) {
      this.$router.push({ name: 'horario-excepcional', params: { id } })
    },

    deletar(id) {
      HorarioExcepcionalService.delete(id).then(() => {
        this.$success('Registro removido com sucesso')
        this.atualizarLista()
      })
    },

    atualizarLista( page ) {

      let filter = Object.assign({},this.filter)

      if (this.filter.descricao) {
        Object.assign(filter, { descricao : `%${this.filter.descricao}%` })
      } else {
        Object.assign(filter, { descricao : null })
      }

      HorarioExcepcionalService.listar({ filter }, (page || 0), this.limit)
        .then(({ data }) => {
          this.horarios = data.content
          this.page = data.page
          this.count = data.totalElements
        })
        .catch(erro => {
          this.$error('Erro ao atualizar lista', erro)
        })
    }
  },

  mounted() {
    this.atualizarLista()
    this.$store.dispatch('setCategorias')
  }
}
</script>

<style scoped>
.actions {
  text-align: center;
  margin-top: 1rem;
}
</style>
