import Api from '@/services/base/Api'
const { format, parseISO } = require('date-fns')

class AgendamentoService extends Api {
  constructor(options) {
    super(options, 'agendamento', 'Agendamento')
  }

  reservar(data) {
    return this.post(data, 'reservar')
  }

  relatorio(idsPostoAtendimento, data, turno) {
    const dataStr = format(typeof data === 'string'? parseISO(data) : data, "yyyy-MM-dd")
    return this.getUrl(`relatorio/agendamentos/postos?idsPostoAtendimento=${idsPostoAtendimento}&dataStr=${dataStr}&turno=${turno}`)
  }

  relatorioPorCpf(cpf, data) {
    const dataStr = data !== null ? (format(typeof data === 'string'? parseISO(data) : data, "yyyy-MM-dd")) : null
    return this.getUrl(`relatorio/${cpf}/${dataStr}`)
  }

  confirmar(data) {
    return this.post(data, 'confirmar')
  }

  listarParaCheckin(cpf) {
    return this.getUrl(`checkin/${cpf}`)
  }

  confirmarCheckin(id) {
    return this.postUrl(`checkin/${id}/confirmar`)
  }

  listarPorCpf() {
    return this.getUrl(`por-cpf`)
  }

  listarBloqueioPorCpfEPeriodo(cpf) {
    return this.getUrl(`por-cpf-bloqueio-periodo/${cpf}`)
  }

  listarPorPosto(idPosto) {
    return this.getUrl(`por-posto/${idPosto}`)
  }

  cancelarAgendamento(id) {
    return this.postUrl(`cancelar/${id}`)
  }

  buscarDatasDisponiveis(idPostoAtendimento, idServico) {
    return this.getUrl(`datas-disponiveis/${idPostoAtendimento}/${idServico}`)
  }
}
export default AgendamentoService.build()
