<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center">Horário excepcional</h1>
    <form class="mt-5" @submit.prevent.stop="submit">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">
          <input-field
            label="Descrição"
            type="text"
            required
            v-model.trim="formData.descricao"
            :validation="$v.formData.descricao"
          />
          <DateField
            label="Data início"
            :validation="$v.formData.dataInicio"
            :enableTime="true"
            v-model="formData.dataInicio"
          />

          <DateField
            label="Data Fim"
            :validation="$v.formData.dataFim"
            :enableTime="true"
            v-model="formData.dataFim"
          />
          <select-field
            label="Posto de Atendimento"
            v-model="formData.postoAtendimento.id"
            :items="postosAtendimento"
            :validation="$v.formData.postoAtendimento.id"
            searchable
            required
            @input="carregaServicos"
          />
          <select-field
            label="Categoria"
            v-model="formData.idCategoria"
            :items="categorias"
            @input="carregaServicos"
          />

          <select-field
            v-show="formData.idCategoria && formData.postoAtendimento.id"
            label="Serviço"
            v-model="formData.idServico"
            :items="servicos"
          />

          <CheckboxItem
            label="Repetir anualmente"
            v-model="formData.fixo"
            :checked="formData.fixo"
          />
          <CheckboxItem
            label="Cancelar Agendamentos"
            v-model="formData.cancelarAgendamentos"
            :checked="formData.cancelarAgendamentos"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <action
          color="lightblue"
          outlined
          text="Cancelar"
          class="mt-lg-0 mt-md-0 mt-2 ml-2"
          @click="retornarParaLista()"
        />
        <action
          color="lightblue"
          outlined
          filled
          text="Gravar"
          class="mt-lg-0 mt-md-0 mt-2 ml-2"
          @click="submeterVerificandoCancelamento()"
        />
      </div>
    </form>
    <ModalConfirm
      :active="modalConfirmAtiva"
      titulo="Confirmar Cancelar Agendamento"
      @confirmar="submit()"
      @fechar="modalConfirmAtiva = false"
    >
      <p>
        Tem certeza que deseja marcar para cancelar automaticamente os agendamentos?
      </p>
    </ModalConfirm>
  </div>
</template>

<script>
import HorarioExcepcionalService from '@/services/HorarioExcepcionalService'
import { required } from 'vuelidate/lib/validators'
import { depoisDe } from '@/validators'
export default {
  name: 'HorarioExcepcional',
  components: {
    // PostoAtendimentoField: () => import('@/components/PostoAtendimentoField'),
    SelectField: () => import('@/components/SelectField'),
    DateField: () => import('@/components/DateField'),
    InputField: () => import('@/components/InputField'),
    Action: () => import('@/components/Action'),
    ModalConfirm: () => import('@/views/ModalConfirm'),
    CheckboxItem: () => import('@/components/CheckboxItem')
  },
  data() {
    return {
      formData: {
        id: null,
        descricao: null,
        postoAtendimento: { id: null },
        categoria: { id: null },
        idCategoria: null,
        idServico: null,
        dataInicio: null,
        dataFim: null,
        fixo: false,
        cancelarAgendamentos: false
      },
      modalConfirmAtiva: false
    }
  },

  validations: {
    formData: {
      descricao: { required },
      dataInicio: { required },
      dataFim: {
        required,
        depoisDe: depoisDe('dataInicio')
      },
      postoAtendimento: { id: { required } },
      fixo: { required }
    }
  },
  computed: {
    categorias() {
      return this.$store.state.categorias
        .map(s => {
          return {
            text: s.nome,
            value: s.id
          }
        })
        .sort((a, b) => (a.id < b.id ? 1 : -1))
    },
    postosAtendimento() {
      return this.$store.state.postosDisponiveis
        .map(p => {
          return {
            text: p.nome,
            value: p.id
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))
    },
    servicos() {
      return this.$store.state.servicos
        .map(s => {
          return {
            text: s.nome,
            value: s.id
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))
    }
  },
  methods: {
    carregaServicos() {
      if (this.formData.idCategoria && this.formData.postoAtendimento.id) {
        this.formData.idServico = null
        this.$store
          .dispatch('setServicos', {
            idCategoria: this.formData.idCategoria,
            idPostoAtendimento: this.formData.postoAtendimento.id
          })
          .then(() => (this.isLoadingPostos = false))
      }
    },
    submeterVerificandoCancelamento() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.formData.cancelarAgendamentos) {
        this.modalConfirmAtiva = true
        return
      }
      this.submit()
    },

    carregar(id) {
      HorarioExcepcionalService.buscar(id).then(resposta => {
        const horario = resposta.data
        if (!horario.postoAtendimento) {
          horario.postoAtendimento = { id: null }
        }
        this.formData = horario
      })
    },

    retornarParaLista() {
      this.$router.push({ name: 'horarios-excepcionais' })
    },

    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const payload = Object.assign({}, this.formData)
      HorarioExcepcionalService.salvar(payload)
        .then(() => {
          this.$success('Registro salvo com sucesso')
          this.retornarParaLista()
        })
        .catch(erro => {
          this.$error('Erro ao salvar registro', erro)
        })
    }
  },

  mounted() {
    this.$store.dispatch('setCategorias')
    this.$store.dispatch('listaTodosPostosAtendimento')
    if (this.$route.params.id && this.$route.params.id !== 'new') {
      this.carregar(this.$route.params.id)
    }
  }
}
</script>
