<template>
  <nav aria-label="Page navigation">
  <div class="pagination">
    <action small filled :dark='dark' color='primary' text="&laquo;" :disabled='page === 0' @click.prevent.stop='prev()'/>

    <action
      filled
      color='primary'
      small
      :dark='dark'
      :text="`${pageItem}`"
      v-for='(pageItem,index) in pages'
      :key='`page-${index}`'
      :active='isActive(pageItem)'
      @click.prevent.stop='goTo(pageItem)'
    />

    <action small filled :dark='dark' color='primary' text="&raquo;" :disabled='lastPage' @click.prevent.stop='next()'/>
  </div>
</nav>
</template>
<script>

import { getPages } from '@/util/pagination-util'

export default {

  components: {
    Action: () => import('@/components/Action')
  },

  props: {
    count: Number,
    limit: Number,
    page: Number,
    dark: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    lastPage () {
      const numberOfPages = this.getNumberOfPages()
      return numberOfPages === (this.page + 1) || numberOfPages === 0
    },
    pages () {
      const numberOfPages = this.getNumberOfPages()
      return getPages(numberOfPages, this.page + 1)
    }
  },

  methods: {

    getNumberOfPages () {
      return Math.ceil(this.count / this.limit)
    },

    goTo (page) {
      if (this.isActive(page)) {
        return false
      }
      this.$emit('changePage', page - 1)
      return false
    },

    next () {
      return this.goTo(this.page + 2)
    },

    prev () {
      return this.goTo(this.page)
    },

    isActive (value) {
      return ((value - 1) === this.page)
    }
  }

}
</script>
<style scoped>
    .pagination {
        justify-content: center;
        display: flex;
    }

    .pagination > .btn {
      margin-right: 5px;
    }

    .pagination {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
</style>

