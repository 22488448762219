<template>
  <span v-if="ehIOS">
    <DateFieldIos
      :label="label"
      :value="value"
      @input="changeIOS"
      :validation="validation"
      :dark="dark"
      :disabled="disabled"
    />
  </span>
  <span v-else @click="openDatePicker" class="date-field">
    <input-field
      :label="label"
      type="text"
      @input="change"
      :value="formatted"
      :validation="validation"
      :dark="dark"
      readonly
    />
    <div class="hidden-input">
      <flat-pickr
        ref="datePicker"
        class="datepicker"
        :config="config"
        v-model="date"
        @on-change="change"
      ></flat-pickr>
    </div>
  </span>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { format, parse, parseISO, isEqual } from 'date-fns'

export default {
  components: {
    flatPickr,
    InputField: () => import('@/components/InputField'),
    DateFieldIos: () => import('@/components/DateFieldIos')
  },

  props: {
    value: [Date, String],
    label: String,
    validation: {
      type: Object,
      default: function() {
        return {}
      }
    },
    enableTime: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      date: null,
      called: undefined
    }
  },

  computed: {
    formatted() {
      if (this.date) {
        const formatting = this.enableTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'

        let date = typeof this.date === 'string' ? parseISO(this.date) : this.date

        return format(date, formatting)
      }
      return ''
    },

    ehIOS() {
      return (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i) ||
        /iPad|iPhone|iPod/.test(window.navigator.platform) ||
        (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
      )
    },

    config() {
      const config = { locale: Portuguese }
      if (this.enableTime) {
        config.enableTime = true
        config.minTime = '00:00'
        config.maxTime = '23:59'
      }
      return config
    }
  },

  methods: {
    change(selectedDates) {
      if (this.called) {
        return
      }
      if(selectedDates === null) {
        this.$emit('input', null)
        return  
      }
      clearTimeout(this.called)
      this.called = setTimeout(() => {
        this.called = undefined
      }, 1000)

      if(!this.format || selectedDates[0] == null) {
        this.$emit('input', selectedDates[0])
      } else {
        this.$emit('input', format(selectedDates[0], this.format))
      }
    },
    changeIOS(value) {
      const novaData = this.converterDataIOS(value)
      let date = typeof this.date === 'string' ? parseISO(this.date) : this.date
      if ((this.date || novaData) && !isEqual(novaData, date)) {
        this.date = novaData
        this.$emit('input', this.date)
      }
    },
    openDatePicker() {
      this.$refs.datePicker.$el._flatpickr.open()
    },
    converterDataIOS(dataStr) {
      if (dataStr && dataStr.length === 10) {
        const data = parse(dataStr, 'dd/MM/yyyy', new Date())
        return data == 'Invalid Date' ? null : data
      }
      return null
    }
  },

  watch: {
    value(newValue) {
      let novaData
      if (typeof newValue === 'string') {
        if (this.ehIOS) {
          novaData = this.converterDataIOS(newValue)
        } else {
          novaData = parseISO(newValue)
        }
      } else {
        novaData = newValue
      }
      let date = typeof this.date === 'string' ? parseISO(this.date) : this.date
      if (novaData != 'Invalid Date' && (this.date || novaData) && !isEqual(novaData, date)) {
        this.date = novaData
      }
      if (newValue === null) {
        this.date = null
      }
    }
  },
  mounted() {
    this.date = this.value
  }
}
</script>
<style scoped>
.date-field {
  position: relative;
}
.hidden-input {
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 0;
}
</style>
