export const getPages = (numberOfPages, current) => {
  const pages = []
  const range = 4

  if (numberOfPages <= range) {
    for (let i = 0; i < numberOfPages; i++) {
      pages.push(i + 1)
    }
    return pages
  }

  const halfRange = Math.floor(range / 2)
  let firstPage = current - halfRange

  while (firstPage < 1) {
    firstPage++
  }
  while ((firstPage + (range - 1)) > numberOfPages) {
    firstPage--
  }

  for (let i = 0; i < range; i++) {
    pages.push(firstPage++)
  }

  return pages
}
