<script>
export default {
  components: {
    Action: () => import('@/components/Action'),
    Modal: () => import('@/components/Modal')
  },
  props: {
    titulo: {
      type: String
    },
    cancelarLabel: {
      type: String,
      default: 'Cancelar'
    },
    confirmarLabel: {
      type: String,
      default: 'OK'
    },
    type: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    confirmar () {
      this.$emit('confirmar')
      this.fechar()
    },
    fechar() {
      this.$emit('fechar')
    }
  }
}
</script>
<template>
  <modal class="modal-confirm" :active="active" @close="fechar()">
    <h2 class="modal-title" v-if='titulo' v-html='titulo' :class="{'texto-perigo': type === 'danger'}"></h2>
    <div class="modal-content">
      <slot />
    </div>
    <div class="modal-actions clearfix">
      <action :text="cancelarLabel" outlined color="lightblue" @click="fechar()" />
      <action :text="confirmarLabel" filled color="primary" @click="confirmar()"/>
    </div>
  </modal>
</template>
