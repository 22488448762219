<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center dont-print">Relatório de agendamentos</h1>
    <div class="mt-5 row dont-print">
      <span class="col-lg-6">
        <PostoAtendimentoField
          v-model="filters.idPostoAtendimento"
          :validation="$v.filters.idPostoAtendimento"
          @input="atualizarLista()"
        />
      </span>
      <span class="col-lg-3 col-md-6">
        <DateField
          label="Data"
          v-model="filters.data"
          :validation="$v.filters.data"
          @input="atualizarLista()"
        />
      </span>
      <span class="col-lg-3 col-md-6">
        <SelectField
          label="Período"
          v-model="filters.turno"
          :items="turnos"
          :validation="$v.filters.turno"
          @input="atualizarLista()"
        />
      </span>
    </div>

    <loading
      font-size="1.7rem"
      text="Carregando"
      direction="vertical"
      color="#2D52A0"
      :active="isLoading"
    />

    <div v-if="agendamentos">
      <h1 class="container-title mt-5 text-center">
        <span class="mb-2 text-center nome-posto print-only">{{
          postoAtendimentoSelecionado.nome
        }}</span>
        Agendamentos (Consulta realizada em {{ dataRetorno.toISOString() | datetime }})
      </h1>
      <div class="mt-5 table-responsive">
        <table v-if="agendamentos.length > 0" class="table">
          <thead>
            <tr>
              <th>Horário</th>
              <th>CPF</th>
              <th>Nome</th>
              <th>Setor</th>
              <th>Status</th>
              <th>Data inclusão</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="agendamento in agendamentos" :key="agendamento.id">
              <td>{{ agendamento.horarioAgendado | datetime }}</td>
              <td>{{ agendamento.cpf | cpf }}</td>
              <td class="text-uppercase">{{ agendamento.nome }}</td>
              <td><div>{{ agendamento.categoria }}</div><div><small>{{agendamento.servico}}</small></div></td>
              <td>{{ agendamento.statusDescricao }}</td>
              <td>{{ agendamento.dataInclusao | datetime }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="aviso-sem-agendamento">
          Nenhum agendamento encontrado para o filtro selecionado
        </div>
      </div>

      <div class="actions dont-print mb-5">
        <action text="Imprimir" filled color="primary" @click="imprimir()" />
        <action text="Atualizar" outlined color="lightblue" @click="atualizarLista()" />
      </div>
    </div>
  </div>
</template>

<script>
import AgendamentoService from '@/services/AgendamentoService'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Loading: () => import('@/components/Loading'),
    Action: () => import('@/components/Action'),
    DateField: () => import('@/components/DateField'),
    SelectField: () => import('@/components/SelectField'),
    PostoAtendimentoField: () => import('@/components/PostoAtendimentoField')
  },

  data() {
    return {
      filters: {
        data: null,
        turno: 1,
        idPostoAtendimento: null
      },
      turnos: [
        { value: 1, text: 'Matutino ' },
        { value: 2, text: 'Vespertino ' }
      ],
      agendamentos: null,
      dataRetorno: null,
      isLoading: false
    }
  },

  validations: {
    filters: {
      idPostoAtendimento: { required },
      data: { required },
      turno: { required }
    }
  },

  computed: {
    postoAtendimentoSelecionado() {
      return (
        this.$store.state.postosAtendimento.filter(
          p => p.id === this.filters.idPostoAtendimento
        )[0] || {}
      )
    }
  },

  methods: {
    atualizarLista() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.agendamentos = null
      this.dataRetorno = null
      this.isLoading = true
      AgendamentoService.relatorio(
        this.filters.idPostoAtendimento,
        this.filters.data,
        this.filters.turno
      )
        .then(resposta => {
          this.dataRetorno = new Date()
          this.agendamentos = resposta.data
          this.$success('Lista atualizada')
        })
        .catch(erro => {
          this.$error('Erro ao atualizar lista', erro)
        })
        .finally(() => (this.isLoading = false))
    },

    imprimir() {
      window.print()
    }
  }
}
</script>

<style scoped>
.nome-posto {
  font-size: 1.2rem;
}
.actions {
  text-align: center;
  margin-top: 2rem;
}

.actions .btn + .btn {
  margin-left: 1rem;
}

.aviso-sem-agendamento {
  text-align: center;
  font-size: 1.6em;
  font-family: 'Lato';
}
.print-only {
  display: none;
}

@media print {
  .container {
    width: 100%;
    margin: 0;
    max-width: unset;
  }
  .dont-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  body {
    font-size: 10pt;
  }
}
</style>
<style>
@media print {
  .vue-notification-group {
    display: none !important;
  }
}
</style>
