var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.url)?_c('button',{ref:"elem",class:['btn', ( _obj = {}, _obj[("btn-" + _vm.color)] = _vm.color, _obj['btn-icon'] =  _vm.icon, _obj['btn-flat'] =  _vm.flat, _obj['btn-filled'] =  _vm.filled, _obj['btn-outlined'] =  _vm.outlined, _obj['btn-submit'] =  _vm.submit, _obj['theme-dark'] =  _vm.dark, _obj['btn-active'] =  _vm.active, _obj['btn-small'] =  _vm.small, _obj['btn-tiny'] =  _vm.tiny, _obj['btn-block'] =  _vm.block, _obj )],attrs:{"type":_vm.checkTypeButton(),"disabled":_vm.disabled},on:{"click":_vm.triggerClickEvent}},[(!_vm.rightIcon)?_vm._t("icon"):_vm._e(),(_vm.text)?_c('span',{staticClass:"btn-text",domProps:{"innerHTML":_vm._s((_vm.text + " " + (_vm.manutencao ? '<small>(Indisponível)</small>' : '')))}}):_vm._e(),(_vm.rightIcon)?_vm._t("icon"):_vm._e()],2):(_vm.urlInternal)?_c('router-link',{class:['btn', ("btn-" + _vm.color), {
    'btn-flat': _vm.flat,
    'btn-filled': _vm.filled,
    'btn-outlined': _vm.outlined,
    'theme-dark': _vm.dark
  }],attrs:{"to":_vm.getUrl()}},[_c('span',{staticClass:"btn-text",domProps:{"innerHTML":_vm._s(_vm.text)}})]):(!_vm.urlInternal)?_c('a',{class:['btn', ( _obj$1 = {}, _obj$1[("btn-" + _vm.color)] = _vm.color, _obj$1['btn-icon'] =  _vm.icon, _obj$1['btn-flat'] =  _vm.flat, _obj$1['btn-filled'] =  _vm.filled, _obj$1['btn-outlined'] =  _vm.outlined, _obj$1['btn-submit'] =  _vm.submit, _obj$1['theme-dark'] =  _vm.dark, _obj$1 )],attrs:{"href":_vm.url,"target":"_blank"}},[(_vm.text)?_c('span',{staticClass:"btn-text",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),_vm._t("icon")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }