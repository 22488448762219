<template>
  <div class="row mb-3 mr-2 horario-atendimento">
    <div class="col-lg-12 mb-2" style="display:flex; align-items:center;">
      <action
        @click="$emit('remover')"
        tiny
        filled
        block
        color="danger"
        class="m-1"
        text="Remover Horário"
      >
        <i slot="icon" class="fas fa-trash fa-xs" />
      </action>
    </div>
    <div class="dont-print col-lg-4">
      <input-field
        
        label="Núm. de Atendentes"
        v-model="horarioAtendimento.quantidadeAtendentes"
        mask="##"
      />
    </div>
    <div class="col-lg-4">
      <input-field label="Início:" v-model="horarioAtendimento.horarioInicio" mask="##:##:##" />
    </div>
    <div class="col-lg-4">
      <input-field label="Fim:" v-model="horarioAtendimento.horarioFim" mask="##:##:##" />
    </div>
    <div class="col-lg-6 dias-semana-wrapper">
      <CheckboxItem
      v-for='(dia,index) in diasDaSemana'
          :key='`dias-da-semana-${index}`'
          @input="v => horarioAtendimento.diasDaSemanaSelecionados[index] = v"
          :label="dia.label"
          :value="horarioAtendimento.diasDaSemanaSelecionados[index]"
          :checked="horarioAtendimento.diasDaSemanaSelecionados[index]"
          v-show='dia.mostrar'
        />
    </div>
    <div class="col-lg-6 row" v-if="!horarioAtendimento.dataEspecifica">
      <div class="row">
        <date-field
          :class="{'col-lg-11' : horarioAtendimento.dataInicio, 'col-lg-12' : !horarioAtendimento.dataInicio}"
          format="yyyy-MM-dd"
          label="Data Início Vigência:"
          v-model="horarioAtendimento.dataInicio"
        />
        <action
          v-if='horarioAtendimento.dataInicio'
          class='col-lg-1 btn-remover-data'
          @click="horarioAtendimento.dataInicio = null"
          tiny
          color="primary"
          title="Remover Data Início Vigência"
        >
          <i slot="icon" class="fas fa-times" />
        </action>
      <date-field
        :class="{'col-lg-11' : horarioAtendimento.dataFim, 'col-lg-12' : !horarioAtendimento.dataFim}"
        format="yyyy-MM-dd"
        label="Data Fim Vigência:"
        v-model="horarioAtendimento.dataFim"
      />
      <action
        v-if='horarioAtendimento.dataFim'
        class='col-lg-1 btn-remover-data'
        @click="horarioAtendimento.dataFim = null"
        tiny
        color="primary"
        title="Remover Data Fim Vigência"
      >
        <i slot="icon" class="fas fa-times" />
      </action>

      </div>
    </div>

    <div class="col-lg-6">
        <div class="dont-print col-lg-12">
          <input-field
            label="Tempo Atendimento"
            v-model="horarioAtendimento.tempoAtendimento"
            mask="##:##"
          />  
        </div>
    </div>
    <div class="col-lg-6">
      <select-field
        label="Perfil"
        v-model="horarioAtendimento.perfil"
        :items="perfis"
      />
    </div>
    <!--<div class="col-lg-4">
      <date-field
        format="yyyy-MM-dd"
        label="Data Específica:"
        v-model="horarioAtendimento.dataEspecifica"
      />
    </div>-->
    <alert type="warning" class="col-lg-8" v-if="horarioAtendimento.dataEspecifica">
      <span
        >Este horário de atendimento servirá somente para o dia
        {{ horarioAtendimento.dataEspecifica | date }}</span
      >
      <action
        @click="horarioAtendimento.dataEspecifica = null"
        tiny
        color="primary"
        class="m-1"
        block
        title="Remover Data Específica"
      >
        <i slot="icon" class="fas fa-times" />
      </action>
    </alert>
    <alert type="warning" class="col-lg-12" v-if="horarioAtendimento.perfil">
      <span
        >Este horário de atendimento estará disponível apenas para {{horarioAtendimento.perfil}}. Remova o perfil para habilitar o atendimento de público geral.</span>
    </alert>
  </div>
</template>
<script>
import { diasDaSemana } from '@/constants/dias-da-semana'
export default {
  props: {
    horarioAtendimento: {
      type: Object
    }
  },
  components:{
    DateField: () => import('@/components/DateField'),
    Alert: () => import('@/components/Alert'),
    Action: () => import('@/components/Action'),
    InputField: () => import('@/components/InputField'),
    CheckboxItem: () => import('@/components/CheckboxItem'),
    SelectField: () => import('@/components/SelectField')
  },
  data() {
    return {
      diasDaSemana,
      perfis: [{text: 'Selecionar', value: null}, {text: 'CFC', value: 'CFC'}],
    }
  },
  watch: {
    horarioAtendimento: function(newVal, old) {
      console.log({newVal}, {old});
    }
  }
}
</script>
<style scoped>
.btn-remover-data {
  position: relative;
  z-index: 1;
}
.dias-semana-wrapper {
  margin-bottom: 10px;
}

.form-checkbox-item + .form-checkbox-item {
  margin-top: 0;
}

.form-checkbox-label > label * {
  display: block;
}
</style>
