<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center dont-print">Check-in</h1>
    <div class="mt-5 col-12 col-lg-6 offset-lg-3">
      <input-field
        label="Informe o CPF do usuário"
        type="text"
        v-model="filters.cpf"
        :mask="'###.###.###-##'"
        required
        :validation="$v.filters.cpf"
        @input="atualizarLista()"
      />
    </div>

    <loading
      font-size="1.7rem"
      text="Carregando"
      direction="vertical"
      color="#2D52A0"
      :active="isLoading"
    />

    <lista-agendamentos :agendamentos='agendamentos' @checkin='efetuarCheckin'>
      <template v-slot:empty>
        Não existem agendamentos para o CPF
        <strong>{{ filters.cpf | cpf }}</strong> em
        <strong>{{ new Date() | date }}</strong>
      </template>
    </lista-agendamentos>
  </div>
</template>

<script>
import AgendamentoService from '@/services/AgendamentoService'
import { limparCpfCnpj } from '@/util/general-util'
import { required } from 'vuelidate/lib/validators'
import { cpf } from '@/validators'

export default {
  components: {
    Loading: () => import('@/components/Loading'),
    ListaAgendamentos: () => import('@/components/ListaAgendamentos'),
    InputField: () => import('@/components/InputField')
  },

  data() {
    return {
      filters: {
        cpf: null
      },
      agendamentos: null,
      isLoading: false
    }
  },

  validations: {
    filters: {
      cpf: { required, cpf }
    }
  },

  methods: {
    efetuarCheckin(agendamento) {
      AgendamentoService.confirmarCheckin(agendamento.id)
        .then(resposta => {
          this.$success('Check-in confirmado')
          Object.assign(agendamento, resposta.data)
          //this.atualizarLista()
        })
        .catch(erro => {
          this.$error('Erro ao confirmar check-in', erro)
        })
    },

    atualizarLista() {
      this.agendamentos = null
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.isLoading = true
      AgendamentoService.listarParaCheckin(limparCpfCnpj(this.filters.cpf))
        .then(resposta => {
          this.agendamentos = resposta.data
        })
        .catch(erro => {
          this.$error('Erro ao atualizar lista', erro)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style src="@/assets/styles/card.css"></style>
