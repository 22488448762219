import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/util/filters'
import Vuelidate from 'vuelidate'
import vClickOutside from 'v-click-outside'
import Notifications from 'vue-notification'
import VCalendar from 'v-calendar'

//import 'bootstrap/dist/css/bootstrap.css' // Todo bootstrap
import 'bootstrap/dist/css/bootstrap-grid.min.css' //Somente bootstrap grid

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(Vuelidate)
Vue.use(vClickOutside)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  theme: {
    dayContent: 'vc-font-medium vc-text-lg vc-cursor-pointer focus:vc-font-bold vc-rounded-full'
  }
})

/* Funções globais */
Vue.prototype.$tratarErro = erro => {
  if (erro) {
    if (erro.message === 'Network Error') {
      return 'Erro de comunicação'
    } else if (
      erro.response &&
      erro.response.data &&
      erro.response.data.length > 0 &&
      erro.response.data[0].mensagemUsuario
    ) {
      return erro.response.data[0].mensagemUsuario
    } else if (erro.response && erro.response.data && erro.response.data.message) {
      return erro.response.data.message
    } else if (erro.mensagemUsuario) {
      return erro.mensagemUsuario
    }
  }
  return 'Não foi possível processar sua requisição'
}

Vue.prototype.$tratarWarning = warning => {
  // Warning String
  if (warning && typeof warning.valueOf() === 'string') {
    return warning
  }
  // Warning via Exception - capturar Mensagem Usuário
  return Vue.prototype.$tratarErro(warning)
}

/* Funções de notificação global */
Vue.prototype.$success = function(title, text, optionalProps) {
  Vue.notify({
    group: 'global',
    type: 'success',
    title,
    text,
    ...optionalProps
  })
}

Vue.prototype.$warn = function(title, warning, optionalProps) {
  Vue.notify({
    group: 'global',
    type: 'warn',
    title,
    text: Vue.prototype.$tratarWarning(warning),
    ...optionalProps
  })
}

Vue.prototype.$error = function(title, error, optionalProps) {
  Vue.notify({
    group: 'global',
    type: 'error',
    title,
    text: Vue.prototype.$tratarErro(error),
    ...optionalProps
  })
  if (error) {
    console.error(error)
  }
}

export class AgendamentoComponent extends HTMLElement {
  montarVueJs() {
    throw new TypeError('Not Implemented')
  }

  connectedCallback() {
    // A Criação do child é importante para que o vuejs não sobrescreva a tag do custom element e tenhamos acesso a ele da página
    const child = document.createElement('div')
    this.appendChild(child)

    let component = this

    this.vue = new Vue({
      router,
      store,
      mounted() {
        component.montarVueJs()

        // Guardar referência do componente no vuex para enviar evento para a página
        this.$store.dispatch('registerComponent', component)
      },
      render: h => h(App)
    })

    this.vue.$mount(child)
  }
}

class DetrannetAgendamento extends AgendamentoComponent {
  static get observedAttributes() {
    return ['token']
  }

  nomeRota() {
    throw new TypeError('Definir o nome da rota')
  }

  montarVueJs() {
    this.vue.$store.dispatch('setToken', this.getAttribute('token'))
    this.vue.$router.replace({ name: this.nomeRota() })
  }
}

export class TransitoAgendamento extends AgendamentoComponent {
  //Registrar aqui os atributos que deseja observar, sem isso ele não é detectado no attributeChangedCallback
  static get observedAttributes() {
    return ['cpf', 'nome', 'email', 'enderecos', 'posto', 'token', 'mensagemCategoriaSemPosto', 'ciretran']
  }

  montarVueJs() {
    this.vue.$store.dispatch('setToken', this.getAttribute('token'))
    const user = {
      cpf: this.getAttribute('cpf'),
      nome: this.getAttribute('nome'),
      email: this.getAttribute('email')
    }

    try {
      const enderecos = JSON.parse(this.getAttribute('enderecos') || '[]')
      const ciretran = JSON.parse(this.getAttribute('ciretran') || '{}')
      this.vue.$store.dispatch('setCidades', enderecos)
      this.vue.$store.dispatch('setEnderecos', enderecos)
      this.vue.$store.dispatch('setCiretran', ciretran)
    } catch (e) {
      console.error('Endereços no formato errado')
    }

    const urlParams = new URLSearchParams(window.location.search)
    const postoQueryString = urlParams.get('posto')

    this.vue.$store.dispatch('setUser', user)
    this.vue.$store.dispatch('setPosto', this.getAttribute('posto') || postoQueryString)
    if (this.getAttribute('mensagemCategoriaSemPosto')) {
      try {
        const mensagens = JSON.parse(this.getAttribute('mensagemCategoriaSemPosto'))
        this.vue.$store.dispatch('setMensagemCategoriaSemPosto', mensagens)
      } catch(e) {
        console.erro('Mensagens de categoria sem posto no formato errado')
      }
    }

    this.vue.$router
      .replace({
        name: 'agendamento'
      })
      .catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      })
  }
}

class DetrannetAgendamentoHorariosExcepcionais extends DetrannetAgendamento {
  nomeRota() {
    return 'horarios-excepcionais'
  }
}

class DetrannetAgendamentoRelatorioAgendamentos extends DetrannetAgendamento {
  nomeRota() {
    return 'relatorio-agendamentos'
  }
}

class TransitoAtendimento extends HTMLElement {
  static get observedAttributes() {
    return ['idSessao', 'cpfOperador']
  }

  connectedCallback() {
    const child = document.createElement('div')
    this.appendChild(child)

    let component = this

    this.vue = new Vue({
      router,
      store,
      mounted() {
        const idSessao = component.getAttribute('idSessao')
        const cpfOperador = component.getAttribute('cpfOperador')
        this.$store.dispatch('registerComponent', component)
        this.$router.replace({
          name: 'atendimento',
          params: {
            idSessao,
            cpfOperador
          }
        })
      },
      render: h => h(App)
    }).$mount(child)
  }
}

class TransitoAgendamentoServicos extends AgendamentoComponent {
  static get observedAttributes() {
    return ['idSessao']
  }

  montarVueJs() {
    this.vue.$store.dispatch('setToken', this.getAttribute('token'))
    this.vue.$router.replace({
      name: 'servicos'
    })
  }
}

class TransitoAgendamentoPostosAtendimento extends AgendamentoComponent {
  static get observedAttributes() {
    return ['idSessao']
  }

  montarVueJs() {
    this.vue.$store.dispatch('setToken', this.getAttribute('token'))
    this.vue.$router.replace({
      name: 'postos-atendimento'
    })
  }
}

class DetrannetAgendamentoCheckin extends DetrannetAgendamento {
  nomeRota() {
    return 'checkin'
  }
}

class DetrannetGerenciarAgendamento extends DetrannetAgendamento {
  nomeRota() {
    return 'gerenciar-agendamentos'
  }
}

class DetrannetDetalhePostosAtendimento extends DetrannetAgendamento {
  nomeRota() {
    return 'detalhe-postos-atendimento'
  }
}

window.customElements.define('transito-agendamento', TransitoAgendamento)
window.customElements.define(
  'detrannet-agendamento-horarios-excepcionais',
  DetrannetAgendamentoHorariosExcepcionais
)
window.customElements.define('transito-atendimento', TransitoAtendimento)
window.customElements.define('transito-agendamento-servicos', TransitoAgendamentoServicos)
window.customElements.define(
  'detrannet-agendamento-relatorio-agendamentos',
  DetrannetAgendamentoRelatorioAgendamentos
)
window.customElements.define('transito-postos-atendimento', TransitoAgendamentoPostosAtendimento)
window.customElements.define('detrannet-agendamento-checkin', DetrannetAgendamentoCheckin)
window.customElements.define('detrannet-gerenciar-agendamentos', DetrannetGerenciarAgendamento)
window.customElements.define('detrannet-detalhe-postos-atendimento', DetrannetDetalhePostosAtendimento)

/** Esse new Vue abaixo é para caso queiramos testar o micro frontend isoladamente, no próprio index.html */

const app = document.getElementById('app')

// Esse if é importante para não dar conflito entre o $store e router do componente
if (app) {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(app)
}
