import { render, staticRenderFns } from "./ServicoList.vue?vue&type=template&id=feb0a2e6&scoped=true"
import script from "./ServicoList.vue?vue&type=script&lang=js"
export * from "./ServicoList.vue?vue&type=script&lang=js"
import style0 from "./ServicoList.vue?vue&type=style&index=0&id=feb0a2e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feb0a2e6",
  null
  
)

export default component.exports