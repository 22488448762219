import { render, staticRenderFns } from "./SelectField.vue?vue&type=template&id=b4bfd2a0&scoped=true"
import script from "./SelectField.vue?vue&type=script&lang=js"
export * from "./SelectField.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/form.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/styles/select.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./SelectField.vue?vue&type=style&index=2&id=b4bfd2a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4bfd2a0",
  null
  
)

export default component.exports