<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center dont-print">Gerenciar Agendamentos</h1>
    <div class="mt-5 col-12 col-lg-6 offset-lg-3">
        <input-field
          v-if="!filters.idPostoAtendimento"
          label="Informe o CPF do usuário"
          type="text"
          v-model="filters.cpf"
          :mask="'###.###.###-##'"
          :validation="$v.filters.cpf"
          @input="atualizarLista()"
        />
        
        <span class="col-lg-6" v-if='!filters.cpf' >
          <PostoAtendimentoField
            :opcaoNenhum="true"
            :apenasDoOperador="true"
            v-model="filters.idPostoAtendimento"
            :validation="$v.filters.idPostoAtendimento"
            :multiple=false
            @input="atualizarListPorPosto()"
          />
        </span>

        <span class="col-lg-3 col-md-6">
          <DateField
            label="Data"
            v-model="filters.data"
            
            @input="filters.cpf ? atualizarLista() : atualizarListPorPosto()"
          />
        </span>

        <span class="col-lg-3 col-md-6" v-if='!filters.cpf'>
          <SelectField
            label="Período"
            v-model="filters.turno"
            :items="turnos"
            :validation="$v.filters.turno"
            @input="atualizarListPorPosto()"
          />
        </span>
    </div>

    <loading
      font-size="1.7rem"
      text="Carregando"
      direction="vertical"
      color="#2D52A0"
      :active="isLoading"
    />

    <lista-agendamentos
      :agendamentos="agendamentos"
      @cancelar="confirmacaoCancelamentoAgendamento"
      :gerenciar="true"
    >
      <template v-slot:empty>
        <template v-if="filters.cpf">
          <span>
            Não existem agendamentos para o CPF
            <strong>{{ filters.cpf | cpf }}</strong> <span v-if='filters.data'>em</span>
          </span>
          <strong class="ml-2">{{ filters.data | date }}</strong>
        </template>
        <template v-else>
          <span>
            Não existem agendamentos para o posto
            <strong>{{postoSelecionado.text}}</strong> em
          </span>
          <strong class="ml-2">{{ filters.data | date }} turno {{turnoSelecionado.text}}</strong>
        </template>
      </template>
    </lista-agendamentos>

    <ModalConfirm
      :active="agendamentoCancelamento != null"
      titulo="Confirmar cancelamento"
      mensagem="Tem certeza que deseja cancelar o agendamento"
      @confirmar="() => { cancelarAgendamento(agendamentoCancelamento) }"
      @fechar="agendamentoCancelamento = null"
    >
      <div v-if="agendamentoCancelamento" class="cancelamento-container">
        <span>Tem certeza que deseja cancelar o agendamento abaixo?</span>
        <div class="dados-cancelamento">
          <table>
            <tbody>
              <tr>
                <th>Horário:</th>
                <td>{{ agendamentoCancelamento.horarioAgendado | datetime }}</td>
              </tr>
              <tr>
                <th>Serviço:</th>
                <td>{{ agendamentoCancelamento.servico }}</td>
              </tr>
              <tr>
                <th>Posto:</th>
                <td>{{ agendamentoCancelamento.postoAtendimento }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ModalConfirm>
  </div>
</template>

<script>
import AgendamentoService from '@/services/AgendamentoService'
import { limparCpfCnpj } from '@/util/general-util'
import { required } from 'vuelidate/lib/validators'
import { cpf } from '@/validators'

export default {
  components: {
    Loading: () => import('@/components/Loading'),
    ListaAgendamentos: () => import('@/components/ListaAgendamentos'),
    InputField: () => import('@/components/InputField'),
    DateField: () => import('@/components/DateField'),
    SelectField: () => import('@/components/SelectField'),
    PostoAtendimentoField: () => import('@/components/PostoAtendimentoField'),
    ModalConfirm: () => import('@/views/ModalConfirm')
  },

  data() {
    return {
      filters: {
        cpf: null,
        data: null,
        turno: 1,
        idPostoAtendimento: null
      },
      turnos: [
        { value: 1, text: 'Matutino ' },
        { value: 2, text: 'Vespertino ' }
      ],
      agendamentos: null,
      agendamentoCancelamento: null,
      isLoading: false
    }
  },

  computed: {
    postosAtendimento() {
      let postos = this.$store.state.postosAtendimento
        .map(p => {
          return {
            text: p.nome,
            value: p.id
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))

      postos.unshift({ text: '', value: null })

      return postos
    },

    postoSelecionado() {
      return this.postosAtendimento.filter(p => p.value === this.filters.idPostoAtendimento)[0]
    },

    turnoSelecionado() {
      return this.turnos.filter(t => t.value === this.filters.turno)[0]
    }
  },

  validations: {
    filters: {
      cpf: { required, cpf },
      idPostoAtendimento: { required },
      data: { required },
      turno: { required }
    },
    porCpf: ['filters.cpf','filters.data'],
    porPosto: ['filters.idPostoAtendimento','filters.data','filters.turno'],
    porSomenteCpf: ['filters.cpf']
  },

  methods: {
    confirmacaoCancelamentoAgendamento(agendamento) {
      this.agendamentoCancelamento = agendamento
    },
    cancelarAgendamento(agendamento) {
      this.isLoading = true
      AgendamentoService.cancelarAgendamento(agendamento.id || agendamento.idAgendamento)
        .then(() => {
          this.$success('Cancelamento confirmado')
          Object.assign(agendamento, { statusDescricao: 'Cancelado pelo usuário', statusId : 3 })
        })
        .catch(erro => {
          this.$error('Erro ao cancelar agendamento', erro)
        })
        .finally(() => (this.isLoading = false))
    },

    atualizarListPorPosto() {
      this.$v.porPosto.$touch()
      if (this.$v.porPosto.$invalid) {
        return
      }
      this.agendamentos = null
      this.dataRetorno = null
      this.isLoading = true
      AgendamentoService.relatorio(
        this.filters.idPostoAtendimento,
        this.filters.data,
        this.filters.turno
      )
        .then(resposta => {
          this.dataRetorno = new Date()
          this.agendamentos = resposta.data
          this.$success('Lista atualizada')
        })
        .catch(erro => {
          this.$error('Erro ao atualizar lista', erro)
        })
        .finally(() => (this.isLoading = false))
    },

    atualizarLista() {
      this.$v.porSomenteCpf.$touch()
      if (this.$v.porSomenteCpf.$invalid) {
        return
      }
      this.agendamentos = null
      this.isLoading = true
      AgendamentoService.relatorioPorCpf(limparCpfCnpj(this.filters.cpf),this.filters.data)
        .then(resposta => {
          this.agendamentos = resposta.data
        })
        .catch(erro => {
          this.$error('Erro ao atualizar lista', erro)
        })
        .finally(() => (this.isLoading = false))
    }
  },

  mounted() {
    this.$store.dispatch('atualizarPostosAtendimento')
  }
}
</script>

<style src="@/assets/styles/card.css"></style>

<style scoped>
.aviso-sem-agendamento {
  text-align: center;
  font-size: 1.6em;
  font-family: 'Lato';
}
.cancelamento-container {
  font-size: 1rem;
}

.cancelamento-container table {
  font-size: 1rem;
  text-align: left;
  margin-top: 1rem;
}

.cancelamento-container th {
  font-weight: normal;
}

.cancelamento-container td {
  font-weight: bold;
  border: none;
}
</style>