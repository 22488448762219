<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center">Posto de Atendimento</h1>
    <form class="mt-5" @submit.prevent.stop="submit">
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2">
          <input-field
            label="Nome"
            required
            v-model.trim="formData.nome"
            :validation="$v.formData.nome"
          />
          <input-field
            label="Endereço"
            v-model.trim="formData.endereco"
            :validation="$v.formData.endereco"
          />
          <input-field
            label="Informacoes"
            readonly
            v-model.trim="formData.informacoes"
            :validation="$v.formData.informacoes"
          />
        </div>
        <div class="container">
          <h2>Horário de atendimento</h2>
          <div class="card" v-for="detalhe in formData.detalhes" :key="detalhe.idCategoria">
            <!-- aqui vai v-for -->
            <p>{{ detalhe.nomeCategoria }}</p>
            <div class="info">
              <div class="grupo">
                <h3>Primeiro turno:</h3>
                <div class="horarios">
                  <input-field
                    label="Início"
                    v-model="detalhe.horarioPrimeiroTurnoInicio"
                    required
                    mask="##:##"
                  />
                  <input-field
                    label="Fim"
                    required
                    v-model="detalhe.horarioPrimeiroTurnoFim"
                    mask="##:##"
                  />
                </div>
              </div>
              <div class="grupo">
                <h3>Segundo turno:</h3>
                <div class="horarios">
                  <input-field
                    label="Início"
                    required
                    v-model="detalhe.horarioSegundoTurnoInicio"
                    mask="##:##"
                  />
                  <input-field
                    label="Fim"
                    required
                    v-model="detalhe.horarioSegundoTurnoFim"
                    mask="##:##"
                  />
                </div>
              </div>
              <div class="grupo">
                <h3>Quantidade de atendentes:</h3>
                <div class="atendentes">
                  <input-field label="Atendentes" v-model="detalhe.atendentes" mask="###" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <action submit color="primary" filled text="Salvar" />
        <action
          color="lightblue"
          outlined
          text="Cancelar"
          class="mt-lg-0 mt-md-0 mt-2 ml-2"
          @click="retornarParaLista()"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import PostoAtendimentoService from '@/services/PostoAtendimentoService'

export default {
  name: 'PostoAtendimento',
  components: {
    InputField: () => import('@/components/InputField'),
    Action: () => import('@/components/Action')
  },
  data() {
    return {
      formData: {
        nome: null,
        endereco: null,
        informacoes: null,
        detalhes: []
      }
    }
  },

  validations: {
    formData: {
      nome: {
        required
      }
    }
  },
  methods: {
    carregarPosto() {
      if (this.$route.params.id && this.$route.params.id !== 'new') {
        PostoAtendimentoService.buscar(this.$route.params.id).then(resposta => {
          this.formData = resposta.data
        })
      } else {
        //Novo cadastro
        PostoAtendimentoService.buscarDetalhes().then(resposta => {
          this.formData.detalhes = resposta.data
        })
      }
    },

    carregarDados() {
      this.carregarPosto()
    },

    retornarParaLista() {
      this.$router.push({ name: 'postos-atendimento' })
    },

    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      //Mapeia para corrigir erro que ocorre
      //quando o usuário apagar um campo de horário, o campo fica "" e não null
      //gerando erro ao serializar no backend

      //TODO: Podemos melhorar e criar algo assim no Interceptor depois,
      //para todos os campos LocalTime de todas requisições
      for (var key in this.formData.detalhes) {
        if (this.formData.detalhes[key].horarioPrimeiroTurnoInicio === '') {
          this.formData.detalhes[key].horarioPrimeiroTurnoInicio = null
        }
        if (this.formData.detalhes[key].horarioPrimeiroTurnoFim === '') {
          this.formData.detalhes[key].horarioPrimeiroTurnoFim = null
        }
        if (this.formData.detalhes[key].horarioSegundoTurnoInicio === '') {
          this.formData.detalhes[key].horarioSegundoTurnoInicio = null
        }
        if (this.formData.detalhes[key].horarioSegundoTurnoFim === '') {
          this.formData.detalhes[key].horarioSegundoTurnoFim = null
        }
      }
      const payload = Object.assign({}, this.formData)
      PostoAtendimentoService.salvar(payload)
        .then(() => {
          this.$success('Posto de atendimento salvo com sucesso')
          this.retornarParaLista()
        })
        .catch(erro => {
          this.$error('Erro ao salvar posto de atendmiento', erro)
        })
    }
  },
  mounted() {
    this.carregarDados()
  }
}
</script>
<style>
@media print {
  html,
  body {
    height: initial !important;
  }

  body {
    overflow: hidden;
  }
}
</style>
<style scoped>
.text-left {
  text-align: left;
}
h2 {
  color: rgba(45, 82, 160, 0.7);
  text-align: center;
  margin: 20px 0px 20px 0px;
}
h3 {
  font-size: 1.4em;
}
.card {
  float: left;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid rgba(45, 82, 160, 0.7);
  padding: 10px;
}
.card p {
  color: rgba(45, 82, 160, 0.7);
  font-weight: 600;
  font-size: 2em;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.card .info {
  margin-left: 30px;
}
.grupo {
  color: rgba(45, 82, 160, 0.7);
  display: flex;
  flex-direction: column;
}
.horarios {
  display: flex;
}
.horarios .form-item {
  margin: 0 10px;
}
.atendentes {
  width: 120px;
}
.atendentes .form-item {
  margin: 0 10px;
}
</style>
