<template>
  <select-field
    label="Posto de Atendimento"
    :value="mutableValue"
    @input="change"
    searchable
    :multiple="multiple"
    :items="postosAtendimentoSelecionavel"
    :disabled="postosAtendimentoSelecionavel.length === 1"
  />
</template>

<script>
export default {
  name: 'PostoAtendimentoField',
  components: {
    SelectField: () => import('@/components/SelectField')
  },

  props: {
    value: {
      default: 0
    },

    opcaoTodos: {
      type: Boolean,
      default: false
    },
    opcaoNenhum: {
      type: Boolean,
      default: false
    },
    apenasDoOperador: {
      type: Boolean,
      default: false
    },multiple:{
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mutableValue: 0
    }
  },

  watch: {
    value(value) {
      this.mutableValue = value
    }
  },

  methods: {
    change(value) {
      this.mutableValue = value
      this.$emit('input', value)
    }
  },

  computed: {
    listaPostos() {
      return this.apenasDoOperador ? this.$store.state.postosAtendimentoOperador : this.$store.state.postosAtendimento;
    },
    postosAtendimentoSelecionavel() {
      const postos = this.listaPostos.map(p => {
        return { value: p.id, text: p.nome }
      })
      .sort((a, b) => (a.text > b.text) ? 1 : -1)

      if (this.opcaoTodos) {
        postos.unshift({ value: 0, text: '(Todos)' })
      }
      if (this.opcaoNenhum) {
        postos.unshift({ value: null, text: '-' })
      }
      return postos
    }
  },

  created() {
    if (
      !this.postosAtendimentoSelecionavel ||
      this.listaPostos.length === 0
    ) {
      const acaoDeCarga = this.apenasDoOperador ? 'atualizarPostosAtendimentoDoOperador' : 'atualizarPostosAtendimento'
      this.$store.dispatch(acaoDeCarga).then(() => {})
    }
  }
}
</script>
