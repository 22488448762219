<script>
export default {
  name: 'Action',
  props: {
    type: {
      type: String,
      default: null
    },
    url: {
      type: [String, Object],
      default: null
    },
    text: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    manutencao: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    urlInternal () {
      return typeof this.url === 'object'
    }
  },
  methods: {
    triggerClickEvent (event) {
      this.type !== 'button' && this.url === '#' && event.preventDefault()
      this.$emit('click', event)
      this.$refs.elem.blur()
    },
    checkTypeButton () {
      return this.submit ? 'submit' : 'button'
    },
    getUrl () {
      return typeof this.url === 'object' ? this.$router.resolve(this.url).href : this.url
    }
  }
}
</script>

<template>
  <button v-if="!url"
    :type="checkTypeButton()"
    :class="['btn', {
      [`btn-${color}`]: color,
      'btn-icon': icon,
      'btn-flat': flat,
      'btn-filled': filled,
      'btn-outlined': outlined,
      'btn-submit': submit,
      'theme-dark': dark,
      'btn-active': active,
      'btn-small': small,
      'btn-tiny': tiny,
      'btn-block': block
    }]"
    :disabled="disabled"
    @click="triggerClickEvent"
    ref="elem">
    <slot name="icon" v-if="!rightIcon"></slot>
    <span class="btn-text" v-if="text" v-html="`${text} ${manutencao ? '<small>(Indisponível)</small>' : ''}`"></span>
    <slot name="icon" v-if="rightIcon"></slot>
  </button>
  <router-link v-else-if="urlInternal"
    :class="['btn', `btn-${color}`, {
      'btn-flat': flat,
      'btn-filled': filled,
      'btn-outlined': outlined,
      'theme-dark': dark
    }]"
    :to="getUrl()">
    <span class="btn-text" v-html="text"></span>
  </router-link>
  <a
    v-else-if="!urlInternal"
    :href="url"
    target="_blank"
    :class="['btn', {
      [`btn-${color}`]: color,
      'btn-icon': icon,
      'btn-flat': flat,
      'btn-filled': filled,
      'btn-outlined': outlined,
      'btn-submit': submit,
      'theme-dark': dark
    }]"
  >
    <span class="btn-text" v-if="text" v-html="text"></span>
    <slot name="icon"></slot>
  </a>
</template>

<style src="@/assets/styles/action.css"></style>
