import Api from '@/services/base/Api'

class CategoriaService extends Api {
  /**
   * @param {*} options
   */
  constructor(options) {
    super(options, 'categoria', 'Categoria')
  }

  listar() {
    return this.get()
  }

  buscar(id) {
    return this.get(id)
  }

  listarCategoriaNaoDoPosto(idsCategorias ) {
    return  this.getUrl(`recupera-categoria-nao-vinculada-posto/${idsCategorias}`)
  }
}

export default CategoriaService.build()