<script>
import { mensagemErroCpf, mensagemErroCnpj, mensagemErroCpfCnpj } from '@/validators'

export default {
  name: 'ValidationMessage',
  components: {
    ValidationSubMessage: () => import('@/components/ValidationSubMessage')
  },
  props: {
    validation: {
      type: Object,
      default: null
    }
  },
  methods: {
    mensagemDetalhadaCpf () {
      return mensagemErroCpf(this.validation.$model)
    },

    mensagemDetalhadaCnpj () {
      return mensagemErroCnpj(this.validation.$model)
    },

    mensagemDetalhadaCpfCnpj () {
      return mensagemErroCpfCnpj(this.validation.$model)
    }
  }
}
</script>

<template v-if="validation">
  <div class="validation-container" v-if="validation.$error">
    <ValidationSubMessage v-if="!validation.required && validation.required != undefined">Campo obrigatório</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.codigoValidacao && validation.required && validation.codigoValidacao != undefined">Código de validação precisa ter 12 caracteres</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.isDate && validation.isDate != undefined">Esta data não é válida</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.email && validation.email != undefined">E-mail inválido</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.sameAsPassword && validation.sameAsPassword != undefined">Senhas não coincidem</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.differentPassword && validation.differentPassword != undefined">Nova senha deve ser diferente</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.minLength && validation.minLength != undefined">Informar no mínimo {{ validation.$params.minLength.min }} caracteres</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.nomeCompleto && validation.nomeCompleto != undefined">Favor informar o nome completo</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.cpf && validation.cpf != undefined">{{ mensagemDetalhadaCpf() }}</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.cpfCnpj && validation.cpfCnpj != undefined">{{ mensagemDetalhadaCpfCnpj() }}</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.cnpj && validation.cnpj != undefined">{{ mensagemDetalhadaCnpj() }}</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.dataFutura && validation.dataFutura != undefined">Esta data já passou</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.limiteDeTrintaDias && validation.limiteDeTrintaDias != undefined">Não é possível selecionar uma data com mais de 30 dias a partir de hoje</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.depoisDe && validation.depoisDe != undefined">Data Início deve ser depois da Data Fim</ValidationSubMessage>
    <ValidationSubMessage v-if="!validation.diaUtil && validation.diaUtil != undefined">Favor selecionar um dia útil</ValidationSubMessage>
  </div>
</template>

<style src="@/assets/styles/validation.css"></style>
