import Api from '@/services/base/Api'

class PostoAtendimentoService extends Api {
  /**
   * @param {*} options
   */
  constructor(options) {
    super(options, 'posto-atendimento', 'posto de atendimento')
  }

  listar() {
    return this.get()
  }

  listarPorOperador() {
    return this.getUrl('listar-por-operador')
  }

  buscar(id) {
    return this.get(id)
  }

  salvar(posto) {
    return this.post(posto)
  }

  buscarPorCidadesEServico(idCidades, idServico) {
    return this.post({idCidades, idServico},'por-cidade')
  }

  buscarPorCidadesECategoria(idCidades, idCategoria) {
    return this.post({idCidades, idCategoria},'listar-por-cidade-categoria')
  }

  buscarDetalhes() {
    return this.getUrl('detalhes')
  }

  deletar(posto) {
    return this.delete(posto)
  }

  buscarDetalhesPosto(id) {
   return this.getUrl(`detalhe-posto-atendimento/${id}`) 
  }

  buscarCidadesQuePostoNaoTem(id) {
    return this.getUrl(`cidades-que-posto-nao-tem/${id}`) 
   }

   salvaDetalhePosto(posto) {
    return this.post(posto,'salva-detalhe-posto-atendimento')
  }

  deletaPostoServico(idPostoServico,idPosto) {
    return this.delete(`excluir-posto-servico/${idPostoServico}/${idPosto}`)
  }

  deletaPostoCidade(idCidade,idPosto) {
    return this.delete(`excluir-posto-cidade/${idCidade}/${idPosto}`)
  }
}

export default PostoAtendimentoService.build()
