import Vue from 'vue'
import { format, parseISO, isValid } from 'date-fns'
import { formatarDocumento } from '@/util/general-util'

Vue.filter('datetime', value => {
  if (!value) {
    return null
  }
  const date = parseISO(value)

  return format(date, 'dd/MM/yyyy HH:mm')
})

Vue.filter('date', value => {
  if (!value) {
    return null
  }
  const date = isValid(value) ? value : parseISO(value)

  return format(date, 'dd/MM/yyyy')
})

Vue.filter('cpf', value => formatarDocumento(value))
