export default {
  overlay(state, value) {
    state.overlay = value
  },
  sessao(state, value) {
    state.sessao = value
  },
  component(state, value) {
    state.component = value
  },
  user(state, value) {
    state.user = value
  },
  categorias(state,value) {
    state.categorias = value
  },
  servicos(state, value) {
    state.servicos = value
  },
  posto(state, value) {
    state.posto = value
  },
  cidades(state, value) {
    state.cidades = value
  },
  enderecos(state, value) {
    state.enderecos = value
  },
  ciretran(state, value) {
    state.ciretran = value
  },
  carregandoPostosDisponiveis(state, value) {
    state.carregandoPostosDisponiveis = value
  },
  postosDisponiveis(state, value) {
    state.postosDisponiveis = value
  },
  setToken(state, value) {
    state.token = value
  },
  setRoles(state, { roles}) {
    state.roles = roles
  },
  setPostosAtendimento(state, value) {
    state.postosAtendimento = value
  },
  setPostosAtendimentoOperador(state, value) {
    state.postosAtendimentoOperador = value
  },
  mensagemCategoriaSemPosto(state, value) {
    state.mensagemCategoriaSemPosto = value
  },
  outEvent(state, event) {
    if (state.component) {
      //Dispara evento para a página, fora do componente. Os dados sempre são passados por detail
      state.component.dispatchEvent(
        new CustomEvent(event.eventName, { detail: event.data })
      )
    }
  }
}
