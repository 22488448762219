import Service from '@/services/base/Service'
import { http } from '@/http.js'

/**
 * @type {Api}
 */
export default class Api extends Service {
  /**
   * @param {Object} opcoes
   * @param {string} recurso
   * @param {string} descricao
   */
  constructor(opcoes, recurso, descricao) {
    super(opcoes)
    this.recurso = recurso
    this.descricao = descricao
  }

  /**
   * @param {Object} objeto
   * @param {string} subrecurso (opcional)
   */
  post(objeto, subrecurso) {
    const endpoint = subrecurso !== undefined ? `${this.recurso}/${subrecurso}` : this.recurso
    return http.post(endpoint, objeto)
  }

  postUrl(subrecurso, objeto) {
    return http.post(`${this.recurso}/${subrecurso}`, objeto)
  }

  /**
   * @param {Object} objeto
   */
  put(objeto) {
    return http.put(this.recurso, objeto)
  }

  /**
   * @param {string} id
   */
  get(id) {
    const endpoint = id !== undefined ? `${this.recurso}/${id}` : this.recurso
    return http.get(endpoint)
  }

  getUrl(subrecurso) {
    return http.get(`${this.recurso}/${subrecurso}`)
  }

  /**
   * @param {string} id
   */
  delete(id) {
    return http.delete(`${this.recurso}/${id}`)
  }



}
