<template>
  <div class="container pb-3">
    <h1 class="container-title mt-5 text-center dont-print">Detalhe Posto</h1>

    <h1 class="container-title mt-5 text-center dont-print">
      {{ postoSelecionado.nome }}
    </h1>

    <div class="grupo">
      <div>
        <template v-for="detalhe in formData.detalhe">
        <div class="card container" v-if='!detalhe.vazio' :key="detalhe.idCategoria">
            <div style="width: 100%">
              <p v-show="detalhe.idCategoria != null && detalhe.novo != 'S'">
                {{ detalhe.nomeCategoria }}
              </p>
            </div>
            <div class="row">
              <div class="dont-print col-lg-6">
                <select-field
                  label="Categoria"
                  v-show="detalhe.idCategoria == null || detalhe.novo == 'S'"
                  :multiple="false"
                  v-model="detalhe.idCategoria"
                  :items="categorias"
                  @input="carregaServicoNovaCategoria(detalhe.posicao - 1)"
                />
                <select-field
                  label="Serviço"
                  :multiple="true"
                  v-model="detalhe.idServicos"
                  :items="detalhe.servicos"
                />
                <div class="" v-if='detalhe.listaPostoServico.length'>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Serviço</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in detalhe.listaPostoServico" >
                      <tr v-if="item.idCategoria == detalhe.idCategoria" :key="item.id">                     
                        <td >
                          <b>{{ item.nomeServico }}</b>
                        </td>
                        <td>
                        <action
                          v-if="item.idCategoria == detalhe.idCategoria"
                          @click="idServicoPostoExclusao = item.id"
                          tiny
                          filled
                          color="danger"
                          class="m-1"
                        >
                          <i slot="icon" class="fas fa-trash fa-xs" />
                        </action>
                        </td>
                      </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="dont-print col-lg-6">
                <horario-atendimento
                  v-for="(horarioAtendimento, index) in detalhe.listaHorarioAtendimento"
                  :key="horarioAtendimento.id"
                  :horarioAtendimento='horarioAtendimento'
                  @remover='detalhe.listaHorarioAtendimento.splice(index, 1)'
                />
                <div class="dont-print col-lg-12 row">
                  <div class="col-lg-12">
                    <action
                      color="primary"
                      block
                      tiny
                      filled
                      text="Adicionar Horário"
                      class="mt-lg-0 mt-md-0 mt-2 ml-0 mb-lg-4"
                      @click="novoHorario(detalhe)"
                    />
                  </div>
                </div>
                <div class="row mr-2 horario-atendimento">
                  <div class="dont-print col-lg-12">
                    <checkbox-item label='Agendamento no mesmo dia' :checked="detalhe.reservaPeriodoSubsequente" v-model='detalhe.reservaPeriodoSubsequente'/>
                    <div v-if='detalhe.reservaPeriodoSubsequente'>Permitindo que seja agendado no mesmo dia, respeitando o período mínimo.</div>
                    <div v-else>Permitindo que seja agendado somente a partir de 48 horas horário atual</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </template>
      </div>
      <div class="text-center"><action
        color="lightblue"
        outlined
        filled
        text="Adicionar Categoria"
        class="mt-lg-0 mt-md-0 mt-2 ml-2"
        @click="novoRegistro()"
      /></div>
      <div class="card container">
        <div>
          <div style="width: 100%">
            <p>Cidades Atendidas pelo posto:</p>
          </div>
          <div class="row" style="margin-top: 20px">
            <div class="col-md-6 col-lg-6">
              <select-field
                label="Cidades"
                :multiple="true"
                v-model="formData.detalhe[0].idCidades"
                :items="cidadesList"
              />
            </div>
            <div class="col-md-6 col-lg-6">
              <table class="table">
                <thead>
                  <tr>
                    <th>Local</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in formData.detalhe[0].listaCidadesPosto" :key="item.id">
                    <td>
                      <b>{{ item.nomeLocal }}</b>
                    </td>
                    <action
                      @click="idCidadeExclusao = item.id"
                      tiny
                      filled
                      color="danger"
                      class="m-1"
                    >
                      <i slot="icon" class="fas fa-trash fa-xs" />
                    </action>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      font-size="1.7rem"
      text="Carregando"
      direction="vertical"
      color="#2D52A0"
      :active="isLoading"
    />
    <div class="row justify-content-center" style="margin-top: 40px;">
      <action
        color="lightblue"
        outlined
        filled
        text="Gravar"
        class="mt-lg-0 mt-md-0 mt-2 ml-2"
        @click="gravarRegistro()"
      />
      <action
        color="lightblue"
        outlined
        filled
        text="Voltar"
        class="mt-lg-0 mt-md-0 mt-2 ml-2"
        @click="voltar(postoSelecionado.id)"
      />
    </div>
    <ModalConfirm
      :active="idCidadeExclusao != null"
      titulo="Confirmar exclusão"
      @confirmar="deletaPostoCidade(idCidadeExclusao)"
      @fechar="idCidadeExclusao = null"
    >
      <p v-if="idCidadeExclusao">Tem certeza que deseja remover a Cidade do Posto Atendimento?</p>
    </ModalConfirm>

    <ModalConfirm
      :active="idServicoPostoExclusao != null"
      titulo="Confirmar exclusão"
      @confirmar="deletaPostoServico(idServicoPostoExclusao)"
      @fechar="idServicoPostoExclusao = null"
    >
      <p v-if="idServicoPostoExclusao">
        Tem certeza que deseja remover o Serviço do Posto Atendimento?
      </p>
    </ModalConfirm>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import PostoAtendimentoService from '@/services/PostoAtendimentoService'
import ServicoService from '@/services/ServicoService'
import CategoriaService from '@/services/CategoriaService'

export default {
  components: {
    ModalConfirm: () => import('@/views/ModalConfirm'),
    Loading: () => import('@/components/Loading'),
    Action: () => import('@/components/Action'),
    SelectField: () => import('@/components/SelectField'),
    CheckboxItem: () => import('@/components/CheckboxItem'),
    HorarioAtendimento: () => import('@/components/HorarioAtendimento'),
  },

  data() {
    return {
      filters: {
        idPostoAtendimento: null
      },
      postoSelecionado: {},
      displayCards: false,
      formData: {
        detalhe: [
          {
            idCidades: [],
            idServicos: [],
            servicos: [],
            listaPostoServico: [],
            listaHorarioAtendimento: [],
            posicao: null,
            novo: 'N'
          }
        ],
        postoAtendimento: { id: null }
      },
      servicosAux: [],
      cidades: [],
      categorias: [],
      newMode: true,
      isLoading: false,
      idCidadeExclusao: null,
      idServicoPostoExclusao: null
    }
  },

  validations: {
    filters: {
      idPostoAtendimento: { required },
    }
  },

  computed: {
    cidadesList() {
      return this.cidades
        .map(c => {
          return {
            text: c.nomeLocal,
            value: c.id
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))
    }
  },

  methods: {
    voltar(param) {
      this.$router.push({ name: 'detalhe-postos-atendimento', params: { param } })
    },
    listarCategoriasQuePostoNaoTem() {
      let idsCategoria = []
      this.formData.detalhe.forEach(d => {
        if(d.idCategoria) {
          idsCategoria.push(d.idCategoria)
        }
      })
      CategoriaService.listarCategoriaNaoDoPosto(idsCategoria)
        .then(resposta => {
          this.categorias = resposta.data.map(c => {
            return {
              text: c.nome,
              value: c.id
            }
          })
        })
        .catch(erro => {
          this.$error('Erro ao atualizar dados na tela', erro)
        })
        .finally(() => (this.isLoading = false))
    },
    buscarCidadesQuePostoNaoTem() {
      PostoAtendimentoService.buscarCidadesQuePostoNaoTem(
        this.postoSelecionado.id
      )
        .then(resposta => {
          this.cidades = resposta.data
          //this.$success('Lista atualizada')
        })
        .catch(erro => {
          this.$error('Erro ao atualizar dados na tela', erro)
        })
        .finally(() => (this.isLoading = false))
    },
    async buscarServicosNaoVinculadoPosto() {
      let requests = []
      if(this.formData?.detalhe[0]?.vazio) {
        return
      }

      for (var i = 0; i < this.formData.detalhe.length; i++) {
        requests.push(
          ServicoService.recuperaServicoPorCategoriaENaoPosto(
            this.postoSelecionado.id,
            this.formData.detalhe[i].idCategoria
          )
        )
      }
      const responses = await Promise.all(requests)
      responses.forEach((response, index) => {
        this.formData.detalhe[index].servicos = response.data.map(s => {
          return {
            text: s.nome,
            value: s.id
          }
        })
      })
    },
    gravarRegistro() {
      this.isLoading = true;
      (this.formData.detalhe || []).forEach(d => {
        d.horarioSegundoTurnoInicio = d.horarioSegundoTurnoInicio == '' ? null : d.horarioSegundoTurnoInicio
        d.horarioSegundoTurnoFim = d.horarioSegundoTurnoFim == '' ? null : d.horarioSegundoTurnoFim
        d.horarioPrimeiroTurnoInicio = d.horarioPrimeiroTurnoInicio == '' ? null : d.horarioPrimeiroTurnoInicio
        d.horarioPrimeiroTurnoFim = d.horarioPrimeiroTurnoFim == '' ? null : d.horarioPrimeiroTurnoFim
      })
      const payload = Object.assign([], this.formData.detalhe)
      PostoAtendimentoService.salvaDetalhePosto(payload)
        .then(() => {
          this.voltar(this.postoSelecionado.id)
          this.$success('Registro salvo com sucesso')
        })
        .catch(erro => {
          this.$error('Erro ao salvar registro', erro)
        })
        .finally(() => (this.isLoading = false))
    },
    deletaPostoServico(idPostoServico) {
      this.isLoading = true
      PostoAtendimentoService.deletaPostoServico(
        idPostoServico,
        this.postoSelecionado.id
      )
        .then(() => {
          this.formData.detalhe.forEach(d => {
            d.listaPostoServico = d.listaPostoServico.filter(ps => ps.id != idPostoServico)
          })
          this.$success('Registro excluido com sucesso')
        })
        .catch(erro => {
          this.$error('Erro ao excluir registro', erro)
        })
        .finally(() => (this.isLoading = false))
    },
    deletaPostoCidade(idCidade) {
      this.isLoading = true
      PostoAtendimentoService.deletaPostoCidade(
        idCidade,
        this.postoSelecionado.id
      )
        .then(() => {
          this.formData.detalhe[0].listaCidadesPosto = this.formData.detalhe[0].listaCidadesPosto.filter(
            cp => cp.id != idCidade
          )
          this.formData.detalhe[0].idCidades = this.formData.detalhe[0].listaCidadesPosto.map(cp => cp.id)
          this.$success('Registro excluido com sucesso')
        })
        .catch(erro => {
          this.$error('Erro ao excluir registro', erro)
        })
        .finally(() => (this.isLoading = false))
    },
    novoRegistro() {
      this.formData.detalhe.push({
        posicao: this.formData.detalhe.length + 1,
        novo: 'S',
        servicos: [],
        listaHorarioAtendimento: [],
        listaPostoServico: [],
        idServicos: [],
        postoAtendimento: this.postoSelecionado
      })
    },
    novoHorario(detalhe) {
      if (!detalhe.listaHorarioAtendimento) {
        detalhe.listaHorarioAtendimento = []
      }
      detalhe.listaHorarioAtendimento.push({
        atendentes: 0,
        tempoAtendimento: '00:10:00',
        horarioInicio: '00:00:00',
        horarioFim: '00:00:00',
        diasDaSemanaSelecionados: [true, true, true, true, true]
      })
    },
    carregaServicoNovaCategoria(posicao) {

      if(this.formData.detalhe[posicao].vazio) {
        return
      }

      ServicoService.recuperaServicoPorCategoriaENaoPosto(
        this.postoSelecionado.id,
        this.formData.detalhe[posicao].idCategoria
      )
        .then(resposta => {
          this.formData.detalhe[posicao].servicos = resposta.data.map(s => {
            return {
              text: s.nome,
              value: s.id
            }
          })
        })
        .catch(erro => {
          this.$error('Erro ao atualizar serviços da categoria selecionada', erro)
        })
        .finally(() => (this.isLoading = false))
    },
    agrupaDetalhe(detalhes) {
      const uniqueElementsBy = (arr, fn) =>
        arr.reduce((acc, v) => {
          if (!acc.some(x => fn(v, x))) acc.push(v)
          return acc
        }, [])

      this.formData.detalhe = uniqueElementsBy(detalhes, (a, b) => a.idCategoria == b.idCategoria)
      
      if(!this.formData.detalhe.length) {
        this.formData = this.novoPosto()
      }
      this.formData.detalhe[0].idCidades = this.formData.detalhe[0].listaCidadesPosto.map(({id}) => id)
    },
    novoPosto () {
      return {
        detalhe: [
          {
            idCidades: [],
            idServicos: [],
            servicos: [],
            listaPostoServico: [],
            listaHorarioAtendimento: [],
            listaCidadesPosto: [],
            posicao: null,
            novo: 'N',
            vazio: true
          }
        ],
        postoAtendimento: { id: this.postoSelecionado.id }
      }
    }
  },
  mounted() {
    this.postoSelecionado = this.$route.params.postoSelecionado
    this.agrupaDetalhe(this.$route.params.param)
    this.buscarCidadesQuePostoNaoTem()
    this.buscarServicosNaoVinculadoPosto()
    this.listarCategoriasQuePostoNaoTem()
  }
}
</script>

<style scoped>
@media print {
  html,
  body {
    height: initial !important;
  }

  body {
    overflow: hidden;
  }
}

.horario-atendimento {
  border: 1px solid #7FC0E4;
  border-radius: 2px;
  padding: 5px;
}

.text-left {
  text-align: left;
}
h2 {
  color: rgba(45, 82, 160, 0.7);
  text-align: center;
  margin: 20px 0px 20px 0px;
}
h3 {
  font-size: 1.4em;
}
.card {
  float: left;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid rgba(45, 82, 160, 0.7);
  padding: 10px;
}
.card p {
  color: rgba(45, 82, 160, 0.7);
  font-weight: 600;
  font-size: 2em;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.card .info {
  margin-left: 30px;
}
.grupo {
  color: rgba(45, 82, 160, 0.7);
  display: flex;
  flex-direction: column;
}
.horarios {
  display: flex;
}
.horarios .form-item {
  margin: 0 10px;
}
.atendentes {
  width: 120px;
}
.atendentes .form-item {
  margin: 0 10px;
}

.nome-posto {
  font-size: 1.2rem;
}
.actions {
  text-align: center;
  margin-top: 2rem;
}

.actions .btn + .btn {
  margin-left: 1rem;
}

.aviso-sem-detahe {
  text-align: center;
  font-size: 1.6em;
  font-family: 'Lato';
}
.print-only {
  display: none;
}

@media print {
  .container {
    width: 100%;
    margin: 0;
    max-width: unset;
  }
  .dont-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  body {
    font-size: 10pt;
  }
  .text-left {
    text-align: left;
  }
  h2 {
    color: rgba(45, 82, 160, 0.7);
    text-align: center;
    margin: 20px 0px 20px 0px;
  }
  h3 {
    font-size: 1.4em;
  }
  .card {
    float: left;
    margin: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid rgba(45, 82, 160, 0.7);
    padding: 10px;
  }
  .card p {
    color: rgba(45, 82, 160, 0.7);
    font-weight: 600;
    font-size: 2em;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  .card .info {
    margin-left: 30px;
  }
  .grupo {
    color: rgba(45, 82, 160, 0.7);
    display: flex;
    flex-direction: column;
  }
  .horarios {
    display: flex;
  }
  .horarios .form-item {
    margin: 0 10px;
  }
  .atendentes {
    width: 120px;
  }
  .atendentes .form-item {
    margin: 0 10px;
  }
}
</style>
<style>
@media print {
  .vue-notification-group {
    display: none !important;
  }
}
</style>
