<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center dont-print">Detalhe Posto</h1>
    <div class="mt-5 row dont-print">
      <span class="col-lg-6">
        <PostoAtendimentoField
          v-model="filters.idPostoAtendimento"
          :validation="$v.filters.idPostoAtendimento"
          :multiple="false"
          @input="atualizarDetalhesPosto()"
        />
      </span>
    </div>

    <loading
      font-size="1.7rem"
      text="Carregando"
      direction="vertical"
      color="#2D52A0"
      :active="isLoading"
    />

    <div v-if="postosAtendimentoDetalhe">
      <table class="table">
        <tbody>
          <tr v-for="item in postosAtendimentoDetalhe" :key="item.id">
            <td v-if='item.nomeCategoria'>
              <div v-for="(horario,index) in item.listaHorarioAtendimento" :key='`horario-${index}`'>
                <span class="info">Horário: das {{horario.horarioInicio}} às {{horario.horarioFim}} </span>
                <b>{{ item.nomeCategoria }}</b> Tempo: {{ horario.tempoAtendimento.slice(0, -3) }} min
                <span class="info">- <dias-semana-selecionados style="text-decoration: underline;" v-if='horario.diasDaSemanaSelecionados' :diasSelecionados='horario.diasDaSemanaSelecionados'/></span>
                <span class="info">- {{horario.quantidadeAtendentes}} Atendente<span v-if='horario.quantidadeAtendentes > 1'>s</span></span>
                <strong class="info" v-if='horario.dataEspecifica'>- Atendimento Especial Dia: {{horario.dataEspecifica | date}}</strong>
                <strong class="info" v-if='horario.dataInicio && horario.dataFim'>- Período Vigência: {{horario.dataInicio | date}} - {{horario.dataFim | date}}</strong>
                <strong class="info" v-if='horario.perfil'>- Perfil: {{horario.perfil}}</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="actions">
        <action text="Editar" filled color="primary" @click="editar(postosAtendimentoDetalhe)" />
      </div>
    </div>

    <div v-else class="aviso-sem-detahe">
      Nenhum Posto encontrado para o filtro selecionado
    </div>
  </div>
</template>

<script>
import PostoAtendimentoService from '@/services/PostoAtendimentoService'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Loading: () => import('@/components/Loading'),
    Action: () => import('@/components/Action'),
    DiasSemanaSelecionados: () => import('@/components/DiasSemanaSelecionados'),
    PostoAtendimentoField: () => import('@/components/PostoAtendimentoField')
  },

  data() {
    return {
      filters: {
        idPostoAtendimento: null
      },
      postosAtendimento: null,
      postosAtendimentoDetalhe: [],
      isLoading: false
    }
  },

  validations: {
    filters: {
      idPostoAtendimento: { required }
    }
  },

  computed: {
    postoAtendimentoSelecionado() {
      return (
        this.$store.state.postosAtendimento.filter(
          p => p.id === this.filters.idPostoAtendimento
        )[0] || {}
      )
    }
  },

  methods: {
    editar(param) {
      this.$router.push({ name: 'detalhe-postos-atendimento-man', params: { param, postoSelecionado: this.postoAtendimentoSelecionado } })
    },
    atualizarDetalhesPosto() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.postosAtendimento = null

      this.isLoading = true
      PostoAtendimentoService.buscarDetalhesPosto(this.filters.idPostoAtendimento)
        .then(resposta => {
          this.postosAtendimentoDetalhe = resposta.data
          /*
          if (!this.postosAtendimentoDetalhe.length) {
            this.postosAtendimentoDetalhe = [
              { postoAtendimento : { id : this.filters.idPostoAtendimento, vazio: true } }
            ]
          }*/

          this.$success('Lista atualizada')
        })
        .catch(erro => {
          this.$error('Erro ao atualizar dados na tela', erro)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  mounted() {
    if (this.$route.params.param !== undefined) {
      this.filters.idPostoAtendimento = this.$route.params.param
      this.postosAtendimento = null
      this.isLoading = true

      PostoAtendimentoService.buscarDetalhesPosto(this.filters.idPostoAtendimento)
        .then(resposta => {
          this.postosAtendimentoDetalhe = resposta.data
          this.$success('Lista atualizada')
        })
        .catch(erro => {
          this.$error('Erro ao atualizar dados na tela', erro)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>
.info {
  margin-left: 5px;
}
.nome-posto {
  font-size: 1.2rem;
}
.actions {
  text-align: center;
  margin-top: 2rem;
}

.actions .btn + .btn {
  margin-left: 1rem;
}

.aviso-sem-detahe {
  text-align: center;
  font-size: 1.6em;
  font-family: 'Lato';
}
.print-only {
  display: none;
}

@media print {
  .container {
    width: 100%;
    margin: 0;
    max-width: unset;
  }
  .dont-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  body {
    font-size: 10pt;
  }
}
</style>
<style>
@media print {
  .vue-notification-group {
    display: none !important;
  }
}
</style>
