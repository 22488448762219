<template>
  <div class="container">
    <h1 class="container-title mt-5 text-center">Serviço</h1>
    <form class="mt-5" @submit.prevent.stop="submit">
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2">
          <input-field
            label="Nome"
            required
            v-model.trim="formData.nome"
            :validation="$v.formData.nome"
          />
          <select-field
            label="Categoria"
            v-model="formData.categoria.id"
            :items="categoriasDisponiveis"
            :validation="$v.formData.categoria.id"
          />
          <input-field
            label="Tempo de Atendimento (Duração)"
            mask="##:##:##"
            required
            v-model.trim="formData.tempoAtendimento"
            :validation="$v.formData.tempoAtendimento"
          />
          <input-field
            label="Descrição"
            required
            v-model.trim="formData.descricao"
            :validation="$v.formData.descricao"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <action 
          submit 
          color="primary" 
          filled 
          text="Salvar"
        />
        <action
          color="lightblue"
          outlined
          text="Cancelar"
          class="mt-lg-0 mt-md-0 mt-2 ml-2"
          @click="retornarParaLista()"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ServicoService from "@/services/ServicoService"
import CategoriaService from "@/services/CategoriaService"

export default {
  name: 'Servico',
  components: {
    InputField: () => import("@/components/InputField"),
    SelectField: () => import("@/components/SelectField"),
    Action: () => import("@/components/Action"),
  },
  data() {
    return {
      formData: {
        nome: null,
        categoria: {id: undefined},
        tempoAtendimento: '00:15:00',
        descricao: null
      },
      categoriasDisponiveis: []
    };
  },

  validations: {
    formData: {
      nome: {
        required
      },
      categoria: {
        id: {required}
      },
      tempoAtendimento: {
        required
      },
      descricao: {
        required
      },
    }
  },
  methods: {
    carregarServico() {
      if (this.$route.params.id && this.$route.params.id !== 'new') {
        ServicoService.buscar(this.$route.params.id).then(resposta => {
          this.formData = resposta.data
        })
      }
    },

    carregarDados() {
      CategoriaService.listar().then(resposta => {
        this.categoriasDisponiveis = resposta.data.map(categoria => {
          return {text: categoria.nome, value: categoria.id}
        });
      })
      .finally(() => {
          this.carregarServico()
      })
    },

    retornarParaLista() {
      this.$router.push({ name: 'servicos' })
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      
      const payload = Object.assign({}, this.formData)
      ServicoService
        .salvar(payload)
        .then(() => {
          this.$success('Serviço salvo com sucesso')
          this.retornarParaLista()
        })
        .catch(erro => {
          this.$error('Erro ao salvar serviço', erro)
        })
    }
  },
  mounted() {
    this.carregarDados()
  }
};
</script>
<style>
@media print {

  html, body {
    height: initial !important;
  }

  body {
    overflow: hidden;
  }
}
  
</style>
<style scoped>
.text-left {
  text-align: left;
}
</style>