import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Agendamento from '../views/Agendamento.vue'
import HorariosExcepcionais from '../views/HorarioExcepcionalList.vue'
import HorarioExcepcional from '../views/HorarioExcepcional.vue'
import Acompanhamento from '../views/Acompanhamento.vue'
import Atendimento from '../views/Atendimento.vue'
import Servico from '../views/Servico.vue'
import ServicoList from '../views/ServicoList.vue'
import PostoAtendimento from '../views/PostoAtendimento.vue'
import PostoAtendimentoList from '../views/PostoAtendimentoList.vue'
import RelatorioAgendamentos from '../views/RelatorioAgendamentos.vue'
import Checkin from '../views/Checkin.vue'
import GerenciarAgendamentos from '../views/GerenciarAgendamentos.vue'
import DetalhePostoAtendimento from '../views/DetalhePostoAtendimento.vue'
import DetalhePostoAtendimentoMan from '../views/DetalhePostoAtendimentoMan.vue'


Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    //alias: '/index.html',
    children: [
      {
        name: 'agendamento',
        path: 'agendamento',
        component: Agendamento
      },
      {
        name: 'acompanhamento',
        path: 'acompanhamento',
        component: Acompanhamento
      },
      {
        path: 'horario-excepcional',
        name: 'horarios-excepcionais',
        component: HorariosExcepcionais,
        props: true
      },
      {
        path: 'horario-excepcional/:id',
        name: 'horario-excepcional',
        component: HorarioExcepcional
      },
      {
        path: 'atendimento',
        name: 'atendimento',
        component: Atendimento
      },
      {
        path: 'servico/:id?',
        name: 'servico',
        component: Servico
      },
      {
        path: 'servicos',
        name: 'servicos',
        component: ServicoList
      },
      {
        path: 'posto-atendimento/:id?',
        name: 'posto-atendimento',
        component: PostoAtendimento
      },
      {
        path: 'postos-atendimento',
        name: 'postos-atendimento',
        component: PostoAtendimentoList
      },
      {
        path: 'relatorio-agendamentos',
        name: 'relatorio-agendamentos',
        component: RelatorioAgendamentos
      },
      {
        path: 'checkin',
        name: 'checkin',
        component: Checkin
      },
      {
        path: 'gerenciar-agendamentos',
        name: 'gerenciar-agendamentos',
        component: GerenciarAgendamentos
      },{
        path: 'detalhe-postos-atendimento',
        name: 'detalhe-postos-atendimento',
        component: DetalhePostoAtendimento
      },
      {
        path: 'detalhe-postos-atendimento-man',
        name: 'detalhe-postos-atendimento-man',
        component: DetalhePostoAtendimentoMan
      }
 
    ]
  }
]

const router = new VueRouter({
  mode: 'abstract', // Modo deve sempre ser 'abstract', pois modo 'history' causa reload na página do microfrontend
  //base: process.env.BASE_URL,
  routes
})

export default router
