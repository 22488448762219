<template>
  <modal class="modal-horario-agendado" :active="active" @close="fechar()">
    <h2 class="modal-title" v-if="titulo" v-html="titulo"></h2>
    <h2 class="modal-title print-only">Agendamento</h2>
    <div class="modal-content" v-if="agendamento">
      <p class="text-left pb-1">
          <strong>Nome:</strong>
          {{agendamento.nome}}
      </p>
      <p class="text-left pb-1">
          <strong>CPF:</strong>
          {{agendamento.cpf | cpf}}
      </p>
      <p class="text-left pb-1">
          <strong>Categoria:</strong>
          {{agendamento.categoria}}
      </p>
      <p class="text-left pb-1">
          <strong>Posto de Atendimento:</strong>
          {{agendamento.posto}}
      </p>
      <p class="text-left pb-1 uppercase" v-if='agendamento.endereco'>
          <strong>Endereco:</strong>
          {{agendamento.endereco}}
      </p>
      <p class="text-left pb-1">
          <strong>Serviço:</strong>
          {{agendamento.servico}}
      </p>
      <p class="text-left pb-1" v-if="agendamento.dataHorario">
          <strong>Data e horário:</strong>
          {{agendamento.dataHorario | datetime}}
      </p>
      <p class="text-left pb-1" v-if="agendamento.data">
          <strong>Data:</strong>
          {{agendamento.data | date}}
      </p>
      <p class="text-left pb-1" v-if="agendamento.horario">
          <strong>Horário:</strong>
          {{agendamento.horario}}
      </p>
      <div class="mt-4 text-center" v-if="temQRCode">
        <p>
          <strong>Apresente o QRCode ao atendente para fazer o check-in:</strong>
        </p>
        <qrcode-vue :value="toQRCode" :size="250" level="H" class="mt-3"></qrcode-vue>
      </div>
    </div>
    <div class="modal-actions clearfix">
        <action text="Imprimir" filled color="primary" @click="imprimir" />
        <action text="Fechar" outlined color="lightblue" @click="fechar()" />
    </div>
  </modal>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    Action: () => import('@/components/Action'),
    Modal: () => import('@/components/Modal'),
    QrcodeVue
  },
  props: {
    titulo: String,
    agendamento: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    temQRCode() {
      return false //TODO escondendo o QRCode enquanto não libera o app de consulta
      // return this.agendamento && this.agendamento.id && this.agendamento.cpf
    },
    toQRCode() {
      if (!this.temQRCode) {
        return
      }
      const data = {
        cpf: this.agendamento.cpf,
        idAgendamento: this.agendamento.id
      }
      return JSON.stringify(data)
    },
  },

  methods: {
    imprimir() {
      window.print()
    },
    fechar() {
      this.$emit('fechar')
    }
  }
}
</script>
<style scoped>
.print-only {
  display: none;
}

.modal-horario-agendado .modal-content p {
  font-size: 1.6em;
}

@media print {
  html,
  body {
    height: initial !important;
  }

  body {
    overflow: hidden;
  }

  .modal-actions {
    display: none;
  }

  .modal-title {
    display: none;
  }

  .print-only {
    display: block !important;
  }
}
</style>
<style>
  .covid {
    background: yellow;
    padding: 10px;
    margin-top: 10px;
  }
</style>